import { ThunkAction } from 'redux-thunk';

import api from 'api';

import { AppState } from '../types';

import { Notification } from 'types';

import * as actions from './actions';

export type NotificationThunkAction = ThunkAction<void, AppState, void, actions.NotificationAction>;

export function getNotifications(): NotificationThunkAction {
  return async (dispatch) => {
    dispatch(actions.getNotificationsStart());

    try {
      const notifications = await api.notification.getNotifications();

      dispatch(actions.getNotificationsSuccess(notifications));
    } catch (error) {
      dispatch(actions.getNotificationsError(error.message));
    }
  };
}

export function editNotification(
  notificationId: number,
  notificationProps: Notification.Props
): NotificationThunkAction {
  return async (dispatch) => {
    dispatch(actions.editNotificationStart());

    try {
      await api.notification.editNotification(notificationId, notificationProps);

      dispatch(actions.editNotificationSuccess(notificationId, notificationProps));
    } catch (error) {
      dispatch(actions.editNotificationError(error.message));
    }
  };
}

export function createNotification(notificationProps: Notification.Props): NotificationThunkAction {
  return async (dispatch) => {
    dispatch(actions.createNotificationStart());

    try {
      await api.notification.createNotification(notificationProps);

      dispatch(getNotifications());
    } catch (error) {
      dispatch(actions.createNotificationError(error.message));
    }
  };
}
