import { Language } from './common';

export type WeightValue = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface Weight {
  flowArea: number;
  weight: WeightValue;
}

export interface Variation {
  id: number | null;
  fileId: number | null;
  session: number;
  duration: number;
  introTimestamp: string | null;
  outroTimestamp: string | null;
  variationFile: File | null;
  status: VariationStatus;
}

export interface Props {
  title: string;
  fullDescription: string;
  shortDescription: string;
  isIntro: boolean;
  isFirst: boolean;
  paidOnly: boolean;
  flowAreaWeights: Weight[];
  language: Language;
  variations: Variation[];
  sharableQuote: {
    url: string | null;
    file: File | null;
  }
}

export interface Session extends Props {
  id: number;
  mainFlowArea: number | null;
}

export enum VariationStatus {
  NOT_CHANGED = 'NOT_CHANGED',
  EDITED = 'EDITED',
  CREATED = 'CREATED',
}
