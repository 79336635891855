import { ThunkAction } from 'redux-thunk';

import api from 'api';
import { User } from 'types';

import { AppState } from '../types';

import * as actions from './actions';

type UserThunkAction<ReturnType = void> = ThunkAction<ReturnType, AppState, void, actions.UserAction>;
export function logout(): UserThunkAction {
  return (dispatch) => {
    dispatch(actions.userLogout());
  };
}

export function getSubscriptions(): UserThunkAction {
  return async (dispatch) => {
    dispatch(actions.getSubscriptionsStart());

    try {
      const result = await api.subscription.getSubscriptions();

      dispatch(actions.getSubscriptionsSuccess(result));
    } catch (err) {
      dispatch(actions.verifyUserEmailError(err.message));
    }
  };
}

export function getUserAccountInformation(): UserThunkAction {
  return async (dispatch) => {
    dispatch(actions.getUserAccountInformationStart());
    try {
      const accountInformation = await api.user.getUserAccountInformation();

      dispatch(actions.getUserAccountInformationSuccess(accountInformation));
    } catch (error) {
      dispatch(actions.getUserAccountInformationError(error.message));
      throw error;
    }
  };
}

export function fetchUserProperties(): UserThunkAction {
  return async (dispatch) => {
    dispatch(actions.fetchUserPropertiesStart());
    try {
      const userProperties = await api.user.fetchUserProperties();
      dispatch(actions.fetchUserPropertiesSuccess(userProperties));
    } catch (e) {
      dispatch(actions.fetchUserPropertiesError());
      throw e;
    }
  };
}

export function fetchAllUserInformation(): UserThunkAction {
  return async (dispatch) => {
    try {
      await dispatch(getUserAccountInformation());
      await dispatch(fetchUserProperties());
      await dispatch(getSubscriptions());
    } catch {}
  };
}

export function login(credentials: User.Credentials): UserThunkAction<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(actions.userLoginStart());

    try {
      const tokenResponse = await api.user.login(credentials);

      dispatch(actions.setAuthToken(tokenResponse.token));

      await dispatch(getUserAccountInformation());
      await dispatch(fetchUserProperties());
      await dispatch(getSubscriptions());

      dispatch(actions.userLoginSuccess());

      return true;
    } catch (err) {
      dispatch(actions.userLoginError(err.message));
      return false;
    }
  };
}

export function register(registrationData: User.RegistrationData): UserThunkAction {
  return async (dispatch) => {
    dispatch(actions.registerStart());

    try {
      const tokenResponse = await api.user.register(registrationData);

      dispatch(actions.registerSuccess(tokenResponse.token));

      await dispatch(getUserAccountInformation());
      await dispatch(fetchUserProperties());
      await dispatch(getSubscriptions());

      return true;
    } catch (err) {
      dispatch(actions.registerError(err.message));

      return false;
    }
  };
}

export function authenticate(authenticationData: User.AuthenticationData): UserThunkAction {
  return async (dispatch) => {
    dispatch(actions.registerStart());

    try {
      const tokenResponse = await api.user.authenticate(authenticationData);

      dispatch(actions.registerSuccess(tokenResponse.token));

      await dispatch(getUserAccountInformation());
      await dispatch(fetchUserProperties());
      await dispatch(getSubscriptions());

      return true;
    } catch (err) {
      dispatch(actions.registerError(err.message));

      return false;
    }
  };
}

export function verifyEmail(path: string): UserThunkAction {
  return async (dispatch) => {
    dispatch(actions.verifyUserEmailStart());

    try {
      await api.user.verifyEmail(path);

      dispatch(actions.verifyUserEmailSuccess());
    } catch (err) {
      dispatch(actions.verifyUserEmailError(err.message));
    }
  };
}
