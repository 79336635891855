import React, { useState, FormEvent, ChangeEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Container,
  Button,
  Box,
  TextField,
  CircularProgress,
  useTheme,
  Typography,
  Card,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import api from 'api';
import { getIsAuthenticated } from 'state/user/selectors';
import { register, authenticate } from 'state/user/thunks';
import { User } from 'types';
import { LoadingState } from 'state/types';
import { SocialProvider } from 'types/user';
import { StoreLinkFooter, FacebookLoginButton, GoogleLoginButton } from 'components';

const RegisterPage = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      padding: theme.spacing(4),
    },
    button: { marginTop: theme.spacing(2), alignSelf: 'stretch' },
    wrapper: {
      display: 'flex',
      backgroundColor: 'coral',
    },
    innerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute' as 'absolute',
    },
    loadingIndicator: {
      color: theme.palette.background.default,
    },
  };

  const isAuthenticated = useSelector(getIsAuthenticated);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState<User.Gender | null>(null);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [socialErrorMessage, setSocialErrorMessage] = useState('');

  const validData = isValidPassword && isValidEmail;

  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);

  const validate = async (): Promise<boolean> => {
    try {
      await api.user.verifyCredentials({ email, password });

      return true;
    } catch (e) {
      if (e.response.data.email) {
        setIsValidEmail(false);
        setEmailErrorMessage(e.response.data.email[0]);
      }

      if (e.response.data.password) {
        setIsValidPassword(false);
        setPasswordErrorMessage(e.response.data.password[0]);
      }

      return false;
    }
  };

  const onRegisterPress = async (event: FormEvent) => {
    event.preventDefault();

    setSocialErrorMessage('');
    setLoadingState(LoadingState.LOADING);
    try {
      const validCredentials = await validate();

      if (!validCredentials) {
        setLoadingState(LoadingState.ERROR);
        return;
      }

      const marketingCompany = props?.location?.search?.trim();

      const registerResult = await dispatch(
        register({
          email,
          password,
          preferredLanguage: i18n.language.slice(0, 2),
          signedForNewsletter: false,
          gender: gender === null ? User.Gender.noAnswer : gender,
          marketingCompany,
        })
      );

      // @ts-ignore
      if (registerResult) {
        setLoadingState(LoadingState.SUCCESS);
      } else {
        setLoadingState(LoadingState.ERROR);
      }
    } catch (e) {
      setLoadingState(LoadingState.ERROR);
    }
  };

  const onSocialRegisterPress = async (accessToken: string, provider: SocialProvider) => {
    setSocialErrorMessage('');
    setEmailErrorMessage('');
    setPasswordErrorMessage('');
    setIsValidPassword(true);
    setIsValidEmail(true);
    setLoadingState(LoadingState.LOADING);
    try {
      const registerResult = await dispatch(
        authenticate({
          accessToken,
          provider,
          preferredLanguage: i18n.language.slice(0, 2),
        })
      );

      // @ts-ignore
      if (registerResult) {
        setLoadingState(LoadingState.SUCCESS);
      } else {
        setLoadingState(LoadingState.ERROR);
        setSocialErrorMessage(t('register.socialError'));
      }
    } catch (e) {
      setLoadingState(LoadingState.ERROR);
      setSocialErrorMessage(t('register.socialError'));
    }
  };

  const onFacebookRegisterPress = async ({ accessToken }: { accessToken: string }) => {
    await onSocialRegisterPress(accessToken, SocialProvider.FACEBOOK);
  };

  const onGoogleRegisterPress = async ({ accessToken, error }: { accessToken: string; error: string; }) => {
    if (['popup_closed_by_user', 'idpiframe_initialization_failed'].includes(error)) return;

    await onSocialRegisterPress(accessToken, SocialProvider.GOOGLE);
  };

  if (isAuthenticated) {
    return <Redirect to="/account" />;
  } else {
    return (
      <Container style={{ flex: 1, display: 'flex' }} maxWidth="sm" color="background">
        <Box flex={1} display="flex" flexDirection="column">
          <Box flex={1} display="flex" flexDirection="column" justifyContent="center" my={4}>
            <Card variant="outlined" style={{ borderWidth: 2, borderColor: '#4fd1a4' }}>
              <form style={styles.container} onSubmit={onRegisterPress}>
                <Typography variant="h5" gutterBottom color="primary">
                  {t('register.title')}
                </Typography>
                <TextField
                  label={t('register.email')}
                  value={email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                    setEmailErrorMessage('');
                    setIsValidEmail(true);
                    setSocialErrorMessage('');
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label={t('register.password')}
                  value={password}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                    setPasswordErrorMessage('');
                    setIsValidPassword(true);
                    setSocialErrorMessage('');
                  }}
                  margin="normal"
                  variant="outlined"
                  type="password"
                />

                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    row={true}
                    name="gender"
                    value={gender}
                    onChange={(event) => setGender(event.target.value as User.Gender)}
                    style={{ marginTop: 8 }}
                  >
                    <FormControlLabel
                      value={User.Gender.female}
                      control={<Radio />}
                      label={t('register.gender.female')}
                    />
                    <FormControlLabel value={User.Gender.male} control={<Radio />} label={t('register.gender.male')} />
                    <FormControlLabel
                      value={User.Gender.diverse}
                      control={<Radio />}
                      label={t('register.gender.diverse')}
                    />
                    <FormControlLabel
                      value={User.Gender.noAnswer}
                      control={<Radio />}
                      label={t('register.gender.noAnswer')}
                    />
                  </RadioGroup>
                </FormControl>

                <Box display="flex" alignItems="center" style={{ height: 64 }}>
                  {loadingState === LoadingState.ERROR && (
                    <Typography color="error">{socialErrorMessage || emailErrorMessage || passwordErrorMessage}</Typography>
                  )}
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={styles.button}
                  disabled={loadingState === LoadingState.LOADING || !validData}
                >
                  {loadingState === LoadingState.LOADING ? (
                    <CircularProgress size={24} style={styles.loadingIndicator} />
                  ) : (
                    t('register.button')
                  )}
                </Button>
                <FacebookLoginButton onPress={onFacebookRegisterPress} loadingState={loadingState} />
                <GoogleLoginButton onPress={onGoogleRegisterPress} loadingState={loadingState} />
              </form>
            </Card>
          </Box>
          <Box mb={2}>
            <StoreLinkFooter />
          </Box>
        </Box>
      </Container>
    );
  }
};

export default withRouter(RegisterPage);
