import { AxiosResponse } from 'axios';

import { Subscription } from 'types';

import { SubscriptionResponse } from './types';

import api from './config';

export const getSubscriptions = (): Promise<Subscription.Subscription[]> =>
  api.get(`/subscriptions/`).then((res: AxiosResponse) => {
    return res.data.map((subscriptionResponse: SubscriptionResponse) => {
      return {
        renewalType: subscriptionResponse.renewal_type,
        expirationDate: subscriptionResponse.expiration_date,
        hasTrialPeriod: subscriptionResponse.has_trial_period,
        autoRenewal: subscriptionResponse.auto_renewal,
      };
    });
  });
