import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Toolbar, Button, IconButton, Box, AppBar } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Logo from 'assets/images/logo.svg';

import { StatisticsPage, AreasPage, SessionsPage, SurveysPage, NotificationsPage, UsersPage } from 'pages';

import { getUserProperties, getIsAuthenticated } from 'state/user/selectors';
import { logout as logoutAction } from 'state/user/thunks';

import AdminRoute from './AdminRoute';
import TrainerRoute from './TrainerRoute';

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#2e2e2e',
  color: 'white',
});

const StyledToolbar = styled(Toolbar)({
  minHeight: 96,
});

const LogoImage = styled('img')({
  height: 32,
});

const AdminRouter = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const userProperties = useSelector(getUserProperties);

  const dispatch = useDispatch();
  const history = useHistory();

  const toAreasPage = () => {
    history.push('/admin/areas');
  };

  const toSessionsPage = () => {
    history.push('/admin/sessions');
  };

  const toSurveysPage = () => {
    history.push('/admin/surveys');
  };

  const toNotificationsPage = () => {
    history.push('/admin/notifications');
  };

  const toUsersPage = () => {
    history.push('/admin/users');
  };

  const toStatisticsPage = () => {
    history.push('/admin/statistics');
  };

  const logout = () => {
    dispatch(logoutAction());
    history.push('/login');
  };

  return (
    <Box>
      <StyledAppBar position="sticky">
        <StyledToolbar>
          <Box style={{ width: '100%' }} alignContent="center" display="flex">
            <LogoImage alt="" src={Logo} />
            {isAuthenticated && (
              <>
                <Box style={{ marginLeft: 32 }} display="flex" flexDirection="row" alignContent="center" flexGrow={1}>
                  <Button color="inherit" onClick={toAreasPage}>
                    Areas
                  </Button>
                  <Button color="inherit" onClick={toSessionsPage}>
                    Sessions
                  </Button>
                  <Button color="inherit" onClick={toSurveysPage}>
                    Surveys
                  </Button>
                  <Button color="inherit" onClick={toNotificationsPage}>
                    Notifications
                  </Button>
                  <Button color="inherit" onClick={toUsersPage}>
                    Users
                  </Button>
                  {userProperties && userProperties.isAdmin && (
                    <Button color="inherit" onClick={toStatisticsPage}>
                      Statistics
                    </Button>
                  )}
                </Box>
                <IconButton onClick={logout} color="inherit">
                  <ExitToAppIcon />
                </IconButton>
              </>
            )}
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <Switch>
        <TrainerRoute exact path="/admin/areas" Component={AreasPage} />
        {/*
    // @ts-ignore */}
        <TrainerRoute exact path="/admin/sessions" Component={SessionsPage} />
        {/*
    // @ts-ignore */}
        <TrainerRoute exact path="/admin/surveys" Component={SurveysPage} />
        {/*
    // @ts-ignore */}
        <TrainerRoute exact path="/admin/notifications" Component={NotificationsPage} />
        {/*
    // @ts-ignore */}
        <TrainerRoute exact path="/admin/users" Component={UsersPage} />
        {/*
    // @ts-ignore */}
        <AdminRoute exact path="/admin/statistics" Component={StatisticsPage} />
        <Route path="/admin/*" component={() => <Redirect to="/login" />} />
      </Switch>
    </Box>
  );
};

export default AdminRouter;
