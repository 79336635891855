import * as React from 'react';

import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { styled, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Hidden, Grid, Toolbar, Box, AppBar } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { getIsAuthenticated } from 'state/user/selectors';

import { logout as logoutAction } from 'state/user/thunks';
import { CatchAllPage, LoginPage, RegisterPage, AccountPage, VerifyEmailPage, ResetPasswordPage } from 'pages';
import { PageGrid, PageFooter } from 'components';

import Logo from 'assets/images/logo.svg';

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#2e2e2e',
  color: 'white',
});

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      [theme.breakpoints.down('sm')]: {
        minHeight: 64,
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 96,
      },
    },
  })
);

const LogoImage = styled('img')({
  height: 32,
});

const UserRouter = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const match = useRouteMatch('/');

  const { t } = useTranslation();

  const logout = () => {
    dispatch(logoutAction());
    history.push('/login');
  };

  const toAccountPage = () => {
    history.push('/account');
  };

  const toRegister = () => {
    history.push('/register');
  };

  const toLogin = () => {
    history.push('/login');
  };

  const renderMenuAuthenticated = () => {
    return (
      <Box>
        <Button startIcon={<AccountCircleIcon />} onClick={toAccountPage}>
          {t('menu.account')}
        </Button>
        <Button onClick={logout}>{t('menu.logout')}</Button>
      </Box>
    );
  };

  const renderMenuNotAuthenticated = () => {
    return (
      <Box>
        <Button onClick={toRegister}>{t('menu.register')}</Button>
        <Button onClick={toLogin}>{t('menu.login')}</Button>
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="column" style={{ minHeight: '100vh', width: '100%' }}>
        <StyledAppBar position="absolute">
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Hidden only="xs">
                <Grid item sm={1} />
              </Hidden>
              <Grid item xs={12} sm={10}>
                <Box style={{ width: '100%' }} justifyContent="space-between" alignContent="center" display="flex">
                  <a href="/">
                    <LogoImage alt="" src={Logo} />
                  </a>
                  {isAuthenticated ? renderMenuAuthenticated() : renderMenuNotAuthenticated()}
                </Box>
              </Grid>
              <Hidden only="xs">
                <Grid item sm={1} />
              </Hidden>
            </Grid>
          </Toolbar>
        </StyledAppBar>
        <Toolbar />
        <Switch>
          {/*
          // @ts-ignore */}
          <Route path="/user/changePassword/:token1/:token2" component={ResetPasswordPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/account" component={AccountPage} />
          <Route path="/registration/confirm-signup" component={VerifyEmailPage} />
          <Route path="/" component={CatchAllPage} />
        </Switch>
      </Box>
      {match && match.isExact ? (
        <PageGrid>
          <PageFooter />
        </PageGrid>
      ) : (
        <PageFooter />
      )}
    </>
  );
};

export default UserRouter;
