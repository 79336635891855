import { Language, Notification } from 'types';

import api from './config';

interface NotificationResponse {
  id: number;
  title: string;
  body: string;
  notification_type: Notification.Type;
  lang: Language;
}

function notificationResponseToNotification(notificationResponse: NotificationResponse): Notification.Notification {
  return {
    id: notificationResponse.id,
    title: notificationResponse.title,
    body: notificationResponse.body,
    type: notificationResponse.notification_type,
    language: notificationResponse.lang,
  };
}

export const getNotifications = (): Promise<Notification.Notification[]> =>
  api
    .get('/notifications/templates/')
    .then((res) =>
      res.data.map((notificationResponse: NotificationResponse) =>
        notificationResponseToNotification(notificationResponse)
      )
    );

function notificationPropsToRemoteNotificationProps(notificationProps: Notification.Props) {
  return {
    title: notificationProps.title,
    body: notificationProps.body,
    lang: notificationProps.language,
    notification_type: notificationProps.type,
  };
}

export const editNotification = (notificationId: number, notificationProps: Notification.Props): Promise<boolean> =>
  api
    .patch(`/notifications/template/${notificationId}/`, notificationPropsToRemoteNotificationProps(notificationProps))
    .then((res) => {
      return res.status === 200;
    });

export const createNotification = (notificationProps: Notification.Props) =>
  api.post('/notifications/templates/', notificationPropsToRemoteNotificationProps(notificationProps)).then((res) => {
    return res.status === 201;
  });
