import { withStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

export const NewButton = withStyles({
  root: {
    margin: 16,
    minWidth: 96,
  },
})(Button);
