import * as React from 'react';
import { compose } from 'redux';

import { CircularProgress, Typography, TextField, Button, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { withTranslation, WithTranslation } from 'react-i18next';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import api from 'api';

import { LoadingState } from 'state/types';

interface PageParams {
  token1: string;
  token2: string;
}

type Props = WithTranslation & RouteComponentProps<PageParams>;

interface State {
  password: string;
  repeatPassword: string;
  loadingState: LoadingState;
  showNotMatchingError: boolean;
}

const ContentContainer = withStyles({
  root: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    marginTop: 48,
  },
})(Box);

class ResetPasswordPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      password: '',
      repeatPassword: '',
      loadingState: LoadingState.IDLE,
      showNotMatchingError: false,
    };
  }

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value,
      showNotMatchingError: event.target.value !== this.state.password,
      loadingState: LoadingState.IDLE,
    });
  };

  onRepeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      repeatPassword: event.target.value,
      showNotMatchingError: event.target.value !== this.state.password,
      loadingState: LoadingState.IDLE,
    });
  };

  onChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();

    const { params } = this.props.match;

    this.setState({
      loadingState: LoadingState.LOADING,
    });

    try {
      await api.user.resetPassword(params.token1, params.token2, this.state.password);

      this.setState({ loadingState: LoadingState.SUCCESS });
    } catch (error) {
      this.setState({ loadingState: LoadingState.ERROR });
    }
  };

  renderSuccess = () => {
    return (
      <ContentContainer>
        <Typography color="textPrimary" variant="h4">
          {this.props.t('resetPassword.success')}
        </Typography>
      </ContentContainer>
    );
  };

  renderError = () => {
    if (this.state.showNotMatchingError) {
      return (
        <Typography color="error" variant="caption">
          {this.props.t('resetPassword.notMatchingError')}
        </Typography>
      );
    } else if (this.state.loadingState === LoadingState.ERROR) {
      return (
        <Typography color="error" variant="caption">
          {this.props.t('resetPassword.backendError')}
        </Typography>
      );
    }
  };

  renderForm() {
    const { loadingState, password, repeatPassword } = this.state;
    const { t } = this.props;

    return (
      <ContentContainer>
        <Typography color="textPrimary" variant="h5">
          {this.props.t('resetPassword.title')}
        </Typography>

        <TextField
          id="password"
          margin="normal"
          label={t('resetPassword.passwordInputLabel')}
          value={password}
          onChange={this.onPasswordChange}
          type="password"
          required
          variant="outlined"
          style={{ width: 300 }}
        />

        <TextField
          id="repeat-password"
          margin="normal"
          label={t('resetPassword.repeatPasswordInputLabel')}
          value={repeatPassword}
          onChange={this.onRepeatPasswordChange}
          type="password"
          required
          variant="outlined"
          style={{ width: 300 }}
        />

        <Box style={{ height: 24 }}>{this.renderError()}</Box>

        <Box mt={2}>
          <Button
            onClick={this.onChangePassword}
            disabled={this.state.showNotMatchingError || loadingState === LoadingState.LOADING}
            component="span"
            variant="contained"
            color="secondary"
            size="large"
          >
            {loadingState === LoadingState.LOADING ? (
              <CircularProgress size={24} style={{ color: '#4fd1a4' }} />
            ) : (
              t('resetPassword.submitButton')
            )}
          </Button>
        </Box>
      </ContentContainer>
    );
  }

  render() {
    const { loadingState } = this.state;

    switch (loadingState) {
      case LoadingState.IDLE:
      case LoadingState.ERROR:
      case LoadingState.LOADING:
        return this.renderForm();
      case LoadingState.SUCCESS:
        return this.renderSuccess();
    }
  }

  private onChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget != null) {
      this.setState({
        ...this.state,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };
}

export default compose(withRouter, withTranslation())(ResetPasswordPage);
