import { ThunkDispatch } from 'redux-thunk';

import { Subscription, User, Area, Session, Statistics, Notification, Users } from 'types';

import { UserAction } from './user/actions';
import { SessionAction } from './session/actions';
import { AreaAction } from './area/actions';
import { SurveyAction } from './survey/actions';
import { NotificationAction } from './notification/actions';

export enum LoadingState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface UserState {
  lastTokenRefreshDate: string | null;
  userProperties: User.Properties | null;
  token: string | null;
  userAccountInformation: User.AccountInformation | null;
  isAuthenticated: boolean;
  emailVerified: LoadingState;
  loginLoading: LoadingState;
  subscriptions: Subscription.Subscription[];
  subscriptionsLoading: LoadingState;
}

export interface AreaState {
  areas: Area.Area[];
}

export interface SessionState {
  sessions: Session.Session[];
  unweightedSessions: Session.Session[];
}

export interface NotificationState {
  notifications: Notification.Notification[];
}

export interface StatisticState {
  loading: LoadingState;
  endpoint: string;
  data: Statistics.Statistic;
}

export interface StatisticsState {
  endpoints: string[];
  data: {
    [statisticEndpoint: string]: StatisticState;
  };
}

export interface UsersState {
  testUsers: Users.TestUser[];
  testUsersLoading: LoadingState;
  convertToTestUserLoading: LoadingState;
}

export interface AppState {
  user: UserState;
  area: AreaState;
  session: SessionState;
  notification: NotificationState;
  statistics: StatisticsState;
  users: UsersState;
}

type AppAction = UserAction | SessionAction | AreaAction | SurveyAction | NotificationAction;

export type AppDispatch = ThunkDispatch<AppState, void, AppAction>;
