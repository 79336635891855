import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { getIsAuthenticated, getUserProperties } from 'state/user/selectors';

interface AdminRouteProps {
  Component: React.ComponentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const AdminRoute = ({ Component, ...rest }: AdminRouteProps) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const userProperties = useSelector(getUserProperties);

  const hasPermissions = userProperties && userProperties.isAdmin;

  const routeRender = (props: {}) =>
    isAuthenticated && hasPermissions ? <Component {...props} /> : <Redirect to="/login" />;

  return <Route {...rest} render={routeRender} />;
};

export default AdminRoute;
