import { User, Subscription } from 'types';

export const GET_USER_ACCOUNT_INFORMATION_START = 'GET_USER_ACCOUNT_INFORMATION_START';
export const GET_USER_ACCOUNT_INFORMATION_SUCCESS = 'GET_USER_ACCOUNT_INFORMATION_SUCCESS';
export const GET_USER_ACCOUNT_INFORMATION_ERROR = 'GET_USER_ACCOUNT_INFORMATION_ERROR';

export const getUserAccountInformationStart = (): GetUserAccountInformationStartAction => ({
  type: GET_USER_ACCOUNT_INFORMATION_START,
});

export interface GetUserAccountInformationStartAction {
  type: typeof GET_USER_ACCOUNT_INFORMATION_START;
}

export const getUserAccountInformationSuccess = (
  userAccountInformation: User.AccountInformation
): GetUserAccountInformationSuccessAction => ({
  type: GET_USER_ACCOUNT_INFORMATION_SUCCESS,
  userAccountInformation,
});

export interface GetUserAccountInformationSuccessAction {
  type: typeof GET_USER_ACCOUNT_INFORMATION_SUCCESS;
  userAccountInformation: User.AccountInformation;
}

export const getUserAccountInformationError = (errorMessage: string): GetUserAccountInformationErrorAction => ({
  type: GET_USER_ACCOUNT_INFORMATION_ERROR,
  errorMessage,
});

export interface GetUserAccountInformationErrorAction {
  type: typeof GET_USER_ACCOUNT_INFORMATION_ERROR;
  errorMessage: string;
}

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

export interface SetAuthTokenAction {
  type: typeof SET_AUTH_TOKEN;
  token: string;
}

export const setAuthToken = (token: string): SetAuthTokenAction => ({ type: SET_AUTH_TOKEN, token });

export const USER_LOGIN_START = 'USER_LOGIN_START';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export interface UserLoginStartAction {
  type: typeof USER_LOGIN_START;
}

export const userLoginStart = (): UserLoginStartAction => ({ type: USER_LOGIN_START });

export interface UserLoginSuccessAction {
  type: typeof USER_LOGIN_SUCCESS;
}

export const userLoginSuccess = (): UserLoginSuccessAction => ({ type: USER_LOGIN_SUCCESS });

export const userLoginError = (errorMessage: string): UserLoginErrorAction => ({
  type: USER_LOGIN_ERROR,
  errorMessage,
});

export interface UserLoginErrorAction {
  type: typeof USER_LOGIN_ERROR;
  errorMessage: string;
}

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const registerStart = (): RegisterStartAction => ({ type: REGISTER_START });

export interface RegisterStartAction {
  type: typeof REGISTER_START;
}

export const registerSuccess = (token: string): RegisterSuccessAction => ({ type: REGISTER_SUCCESS, token });

export interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS;
  token: string;
}

export const registerError = (errorMessage: string): RegisterErrorAction => ({
  type: REGISTER_ERROR,
  errorMessage,
});

export interface RegisterErrorAction {
  type: typeof REGISTER_ERROR;
  errorMessage: string;
}

export const FETCH_USER_PROPERTIES_START = 'FETCH_USER_PROPERTIES_START';
export const FETCH_USER_PROPERTIES_SUCCESS = 'FETCH_USER_PROPERTIES_SUCCESS';
export const FETCH_USER_PROPERTIES_ERROR = 'FETCH_USER_PROPERTIES_ERROR';

export const fetchUserPropertiesStart = (): FetchUserPropertiesStartAction => ({ type: FETCH_USER_PROPERTIES_START });
export interface FetchUserPropertiesStartAction {
  type: typeof FETCH_USER_PROPERTIES_START;
}

export const fetchUserPropertiesSuccess = (userProperties: User.Properties): FetchUserPropertiesSuccessAction => ({
  type: FETCH_USER_PROPERTIES_SUCCESS,
  userProperties,
});
export interface FetchUserPropertiesSuccessAction {
  type: typeof FETCH_USER_PROPERTIES_SUCCESS;
  userProperties: User.Properties;
}

export const fetchUserPropertiesError = (): FetchUserPropertiesErrorAction => ({ type: FETCH_USER_PROPERTIES_ERROR });
export interface FetchUserPropertiesErrorAction {
  type: typeof FETCH_USER_PROPERTIES_ERROR;
}

export const USER_LOGOUT = 'USER_LOGOUT';

export const userLogout = (): UserLogoutAction => ({ type: USER_LOGOUT });
export interface UserLogoutAction {
  type: typeof USER_LOGOUT;
}

export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const updateToken = (token: string) => ({ type: UPDATE_TOKEN, token });
export interface UpdateTokenAction {
  type: typeof UPDATE_TOKEN;
}

export const SET_LAST_TOKEN_REFRESH_DATE = 'SET_LAST_TOKEN_REFRESH_DATE';

export const setLastTokenRefreshDate = (date: string) => ({ type: SET_LAST_TOKEN_REFRESH_DATE, date });
export interface SetLastTokenRefreshDateAction {
  type: typeof SET_LAST_TOKEN_REFRESH_DATE;
  date: string;
}

export const VERIFY_USER_EMAIL_START = 'VERIFY_USER_EMAIL_START';
export const VERIFY_USER_EMAIL_SUCCESS = 'VERIFY_USER_EMAIL_SUCCESS';
export const VERIFY_USER_EMAIL_ERROR = 'VERIFY_USER_EMAIL_ERROR';

export const verifyUserEmailStart = (): VerifyUserEmailStartAction => ({
  type: VERIFY_USER_EMAIL_START,
});

export interface VerifyUserEmailStartAction {
  type: typeof VERIFY_USER_EMAIL_START;
}

export interface VerifyUserEmailSuccessAction {
  type: typeof VERIFY_USER_EMAIL_SUCCESS;
}

export const verifyUserEmailSuccess = (): VerifyUserEmailSuccessAction => ({
  type: VERIFY_USER_EMAIL_SUCCESS,
});

export const verifyUserEmailError = (errorMessage: string): VerifyUserEmailErrorAction => ({
  type: VERIFY_USER_EMAIL_ERROR,
  errorMessage,
});

export interface VerifyUserEmailErrorAction {
  type: typeof VERIFY_USER_EMAIL_ERROR;
  errorMessage: string;
}

export const GET_SUBSCRIPTIONS_START = 'GET_SUBSCRIPTIONS_START';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR';

export const getSubscriptionsStart = (): GetSubscriptionsStartAction => ({
  type: GET_SUBSCRIPTIONS_START,
});

export interface GetSubscriptionsStartAction {
  type: typeof GET_SUBSCRIPTIONS_START;
}

export interface GetSubscriptionsSuccessAction {
  type: typeof GET_SUBSCRIPTIONS_SUCCESS;
  subscriptions: Subscription.Subscription[];
}

export const getSubscriptionsSuccess = (subscriptions: Subscription.Subscription[]): GetSubscriptionsSuccessAction => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  subscriptions,
});

export const getSubscriptionsError = (errorMessage: string): GetSubscriptionsErrorAction => ({
  type: GET_SUBSCRIPTIONS_ERROR,
  errorMessage,
});

export interface GetSubscriptionsErrorAction {
  type: typeof GET_SUBSCRIPTIONS_ERROR;
  errorMessage: string;
}

export type UserAction =
  | SetAuthTokenAction
  | UserLoginSuccessAction
  | UserLoginStartAction
  | UserLoginErrorAction
  | RegisterSuccessAction
  | RegisterStartAction
  | RegisterErrorAction
  | UserLogoutAction
  | UpdateTokenAction
  | GetUserAccountInformationStartAction
  | GetUserAccountInformationSuccessAction
  | GetUserAccountInformationErrorAction
  | SetLastTokenRefreshDateAction
  | FetchUserPropertiesStartAction
  | FetchUserPropertiesErrorAction
  | FetchUserPropertiesSuccessAction
  | VerifyUserEmailStartAction
  | VerifyUserEmailErrorAction
  | VerifyUserEmailSuccessAction
  | GetSubscriptionsStartAction
  | GetSubscriptionsErrorAction
  | GetSubscriptionsSuccessAction;
