import { Session } from 'types';

import { SessionAction, GET_SESSIONS_SUCCESS, GET_UNWEIGHTED_SESSIONS_SUCCESS, EDIT_SESSION_SUCCESS } from './actions';

import { SessionState } from '../types';

const initialState = {
  sessions: [],
  unweightedSessions: [],
};

function replaceSession(sessions: Session.Session[], sessionId: number, newSessionProps: Session.Props) {
  return sessions.map((session: Session.Session) => {
    if (session.id === sessionId) {
      return {
        ...session,
        ...newSessionProps,
      };
    } else {
      return session;
    }
  });
}

export const sessionReducer = (state = initialState, action: SessionAction): SessionState => {
  switch (action.type) {
    case GET_SESSIONS_SUCCESS:
      return { ...state, sessions: action.sessions };

    case GET_UNWEIGHTED_SESSIONS_SUCCESS:
      return { ...state, unweightedSessions: action.unweightedSessions };

    case EDIT_SESSION_SUCCESS:
      return {
        ...state,
        sessions: replaceSession(state.sessions, action.sessionId, action.sessionProps),
        unweightedSessions: replaceSession(state.unweightedSessions, action.sessionId, action.sessionProps),
      };

    default:
      return state;
  }
};
