import { AxiosResponse } from 'axios';

import { User } from 'types';

import { TokenResponse, UserPropertiesResponse, UserAccountInformationResponse } from './types';

import api, { refreshAuthenticationUrl } from './config';

export interface RegistrationRequest {
  email: string;
  password1: string;
  password2: string;
  preferred_language: string;
  gender: string | null;
  signed_for_newsletter: boolean;
}

export const login = (credentials: User.Credentials): Promise<TokenResponse> =>
  api.post('/authenticate/', credentials).then((res: AxiosResponse) => {
    return res.data;
  });

export const authenticate = (authenticateData: User.AuthCredentials): Promise<TokenResponse> =>
  api
    .post(`/authenticate/${authenticateData.provider.toLowerCase()}/`, {
      access_token: authenticateData.accessToken,
      preferred_language: authenticateData.preferredLanguage,
    })
    .then((res: AxiosResponse) => res.data);

function registrationDataToRequestData(registrationData: User.RegistrationData): RegistrationRequest {
  return {
    email: registrationData.email,
    password1: registrationData.password,
    password2: registrationData.password,
    preferred_language: registrationData.preferredLanguage,
    gender: registrationData.gender !== User.Gender.noAnswer ? registrationData.gender : null,
    signed_for_newsletter: registrationData.signedForNewsletter,
  };
}

export const register = (registrationData: User.RegistrationData): Promise<TokenResponse> => {
  const { marketingCompany } = registrationData;
  return api
    .post(`/registration/signup${marketingCompany}`, registrationDataToRequestData(registrationData))
    .then((res) => res.data);
};

export const refreshToken = (token: string): Promise<TokenResponse> =>
  api.post(refreshAuthenticationUrl, { token }).then((res: AxiosResponse) => res.data);

export const getUserAccountInformation = (): Promise<User.AccountInformation> =>
  api.get('/user/account/').then((res: AxiosResponse) => {
    const userAccountInformationResponse: UserAccountInformationResponse = res.data;

    const {
      image_url,
      gender,
      username,
      birthday,
      signed_for_newsletter,
      date_joined,
      first_name,
      last_name,
    } = userAccountInformationResponse;

    const convertedGender: User.Gender = gender == null ? User.Gender.noAnswer : (gender as User.Gender);

    return {
      imageUrl: image_url,
      gender: convertedGender,
      username,
      birthday,
      signedForNewsletter: signed_for_newsletter,
      dateJoined: date_joined,
      firstName: first_name,
      lastName: last_name,
    };
  });

export const resetPassword = (token1: string, token2: string, newPassword: string) =>
  api.put(
    `/user/changePassword/${token1}/${token2}/`,
    JSON.stringify({ new_password: newPassword, new_password1: newPassword })
  );

export const fetchUserProperties = (): Promise<User.Properties> =>
  api.get('/user/rights/').then((res: AxiosResponse) => {
    const userPropertiesResponse: UserPropertiesResponse = res.data;

    const {
      is_trainer,
      is_admin,
      has_trial,
      has_paid_subscription,
      has_lifetime_subscription,
      is_confirmed,
      is_test,
    } = userPropertiesResponse;

    return {
      isTrainer: is_trainer,
      isAdmin: is_admin,
      hasTrial: has_trial,
      hasPaidSubscription: has_paid_subscription,
      hasLifetimeSubscription: has_lifetime_subscription,
      isConfirmed: is_confirmed,
      isTest: is_test,
    };
  });

export const verifyEmail = (path: string): Promise<boolean> =>
  api.get(path).then((res: AxiosResponse) => {
    return res.status === 200 || res.status === 204;
  });

export const resendConfirmationLink = () =>
  api.post('/registration/resendConfirmationLink/').then((res: AxiosResponse) => {
    return res.status === 200 || res.status === 204;
  });

interface VerifyCredentials {
  email: string;
  password: string;
}

export const verifyCredentials = (credentials: VerifyCredentials) =>
  api.post('/registration/verify/', credentials).then((res) => res.data);
