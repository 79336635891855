import { ThunkAction } from 'redux-thunk';

import api from 'api';

import { Session, Language } from 'types';

import { AppState } from '../types';

import * as actions from './actions';
import { Variation, VariationStatus } from 'types/session';

export type SessionThunkAction = ThunkAction<void, AppState, void, actions.SessionAction>;

export function getAllSessions(): SessionThunkAction {
  return async (dispatch) => {
    dispatch(actions.getSessionsStart());

    try {
      const sessions = await Promise.all([
        api.session.getSessions(Language.DE),
        api.session.getSessions(Language.EN),
        api.session.getUnweightedSessions(),
      ]);

      dispatch(actions.getSessionsSuccess(sessions[0].concat(sessions[1])));
      dispatch(actions.getUnweightedSessionsSuccess(sessions[2]));
    } catch (error) {
      dispatch(actions.getSessionsError(error.message));
    }
  };
}

export function editSession(sessionId: number, sessionProps: Session.Props): SessionThunkAction {
  return async (dispatch) => {
    dispatch(actions.editSessionStart());

    try {
      await api.session.editSession(sessionId, sessionProps);

      const variations = [...sessionProps.variations];

      // Implement variation changes related to duration/introTimestamp/outroTimestamp
      for (let i = 0; i < variations.length; i = i + 1) {
        const variation = variations[i];
        if (variation.status === VariationStatus.CREATED) {
          const result: Variation = await api.session.createVariation(sessionId, variation);
          variation.id = result.id;
        } else if (variation.status === VariationStatus.EDITED && variation.id) {
          await api.session.updateVariation(variation.id, variation);
        }
        variation.status = VariationStatus.NOT_CHANGED;
      }

      // Upload audio files
      for (let i = 0; i < variations.length; i = i + 1) {
        const variation = variations[i];
        if (variation.variationFile) {
          if (variation && variation.id) {
            dispatch(actions.uploadAudioStart());
            try {
              await api.session.uploadVariationAudio(variation.id, variation.variationFile);
              variation.variationFile = null;
            } catch (error) {
              dispatch(actions.uploadAudioError(error.message));
            }
          }
        }
      }

      if (sessionProps.sharableQuote.file) {
        await api.session.uploadSessionImage(sessionId, sessionProps.sharableQuote.file);
        sessionProps.sharableQuote.file = null;
        // Workaround for session refresh. Update sharableQuote.url when the back-end can provide 
        // such information in the uploadSessionImage response.
        dispatch(getAllSessions());
      }

      dispatch(actions.editSessionSuccess(sessionId, sessionProps));
    } catch (error) {
      dispatch(actions.editSessionError(error.message));
    }
  };
}

export function createSession(sessionProps: Session.Props): SessionThunkAction {
  return async (dispatch) => {
    dispatch(actions.createSessionStart());

    try {
      await api.session.createSession(sessionProps);

      dispatch(getAllSessions());
    } catch (error) {
      dispatch(actions.createSessionError(error.message));
    }
  };
}
