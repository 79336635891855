import { Area } from 'types';

export const GET_ALL_AREAS_START = 'GET_ALL_AREAS_START';
export const GET_ALL_AREAS_SUCCESS = 'GET_ALL_AREAS_SUCCESS';
export const GET_ALL_AREAS_ERROR = 'GET_ALL_AREAS_ERROR';

export interface GetAllAreasStart {
  type: typeof GET_ALL_AREAS_START;
}

export const getAllAreasStart = (): GetAllAreasStart => ({
  type: GET_ALL_AREAS_START,
});

export interface GetAllAreasSuccess {
  type: typeof GET_ALL_AREAS_SUCCESS;
  areas: Area.Area[];
}

export const getAllAreasSuccess = (areas: Area.Area[]): GetAllAreasSuccess => ({
  type: GET_ALL_AREAS_SUCCESS,
  areas,
});

export interface GetAllAreasError {
  type: typeof GET_ALL_AREAS_ERROR;
  message: string;
}

export const getAllAreasError = (message: string): GetAllAreasError => ({
  type: GET_ALL_AREAS_ERROR,
  message,
});

export type AreaAction = GetAllAreasStart | GetAllAreasSuccess | GetAllAreasError;
