import * as React from 'react';

import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import BackgroundImage from 'assets/images/background.jpg';

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    background: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: '100%',
      flex: 1,
      backgroundImage: `url(${BackgroundImage})`,
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: 'left 10% top 0px',
      },
      [theme.breakpoints.up('md')]: {
        backgroundPosition: 'top',
      },
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  })
);

export function PageBackground(props: Props) {
  const classes = useStyles();

  return <Box className={classes.background}>{props.children}</Box>;
}
