import _ from 'lodash';

import { Statistics } from 'types';

import api from './config';

export const getEndpoints = (): Promise<string[]> => api.get('/statistics/').then((res) => res.data.endpoints);

function capitalize(s: string) {
  return s.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

function keyToTitle(key: string) {
  return capitalize(key.replace(/_/g, ' '));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function statisticResponseToStatistic(statisticResponse: any): Statistics.Statistic {
  const keys = _.keys(statisticResponse);

  const data = statisticResponse[keys[0]];
  const title = keyToTitle(keys[0]);

  if (data.graphData) {
    return {
      type: Statistics.Type.GRAPH,
      data: data.graphData,
      title,
    };
  } else if (data.tableData) {
    return {
      type: Statistics.Type.TABLE,
      data: data.tableData,
      title,
    };
  } else {
    return {
      type: Statistics.Type.KEY_VALUE,
      data,
      title,
    };
  }
}

export const getStatistic = (endpoint: string): Promise<Statistics.Statistic> =>
  api.get('/statistics' + endpoint).then((res) => statisticResponseToStatistic(res.data));
