import {
  EndpointAction,
  GET_ENDPOINTS_SUCCESS,
  RESET_STATISTICS,
  GET_STATISTIC_SUCCESS,
  GET_STATISTIC_START,
  GET_STATISTIC_ERROR,
} from './actions';

import { LoadingState, StatisticsState } from '../types';

const initialState = {
  endpoints: [],
  data: {},
};

export const statisticsReducer = (state = initialState, action: EndpointAction): StatisticsState => {
  switch (action.type) {
    case GET_ENDPOINTS_SUCCESS:
      return { ...state, endpoints: action.endpoints };

    case GET_STATISTIC_START:
      return {
        ...state,
        data: {
          ...state.data,
          [action.endpoint]: {
            endpoint: action.endpoint,
            loading: LoadingState.LOADING,
          },
        },
      };

    case GET_STATISTIC_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.endpoint]: {
            endpoint: action.endpoint,
            loading: LoadingState.SUCCESS,
            data: action.statistic,
          },
        },
      };

    case GET_STATISTIC_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.endpoint]: {
            endpoint: action.endpoint,
            loading: LoadingState.ERROR,
          },
        },
      };

    case RESET_STATISTICS:
      return initialState;

    default:
      return state;
  }
};
