import { Notification } from 'types';

import { NotificationAction, GET_NOTIFICATIONS_SUCCESS, EDIT_NOTIFICATION_SUCCESS } from './actions';

import { NotificationState } from '../types';

const initialState = {
  notifications: [],
};

function replaceNotification(
  notifications: Notification.Notification[],
  notificationId: number,
  newNotificationProps: Notification.Props
) {
  return notifications.map((notification: Notification.Notification) => {
    if (notification.id === notificationId) {
      return {
        ...notification,
        ...newNotificationProps,
      };
    } else {
      return notification;
    }
  });
}

export const notificationReducer = (state = initialState, action: NotificationAction): NotificationState => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: action.notifications };

    case EDIT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: replaceNotification(state.notifications, action.notificationId, action.notificationProps),
      };

    default:
      return state;
  }
};
