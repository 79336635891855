import { ThunkAction } from 'redux-thunk';

import api from 'api';

import { AppState } from '../types';

import * as actions from './actions';

type UsersThunkAction<ReturnType = void> = ThunkAction<ReturnType, AppState, void, actions.UsersAction>;

export function fetchTestUsers(): UsersThunkAction {
  return async (dispatch) => {
    dispatch(actions.getTestUsersStart());

    try {
      const testUsers = await api.users.getTestUsers();

      dispatch(actions.getTestUsersSuccess(testUsers));
    } catch (error) {
      dispatch(actions.getTestUsersError(error.message));
    }
  };
}

export function convertToTestUser(email: string): UsersThunkAction {
  return async (dispatch) => {
    dispatch(actions.convertToTestUserStart());

    try {
      await api.users.convertToTestUser(email);

      dispatch(actions.convertToTestUserSuccess());
    } catch (error) {
      dispatch(actions.convertToTestUserError(error.message));
    }
  };
}
