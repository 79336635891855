import { Language, Session } from 'types';

import api from './config';

import { uploadFile } from './audioFile';
import { VariationStatus } from 'types/session';

interface WeightResponse {
  flow_area: number;
  weight: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

interface Variation {
  id: number;
  file_id: number | null;
  session: number;
  duration: number;
  intro_timestamp: string | null;
  outro_timestamp: string | null;
}

export interface SessionResponse {
  id: number;
  title: string;
  full_description: string;
  short_description: string;
  main_flow_area: number;
  is_intro: boolean;
  is_first: boolean;
  paid_only: boolean;
  completed: boolean;
  flow_area_weights: WeightResponse[];
  lang: 'en' | 'de';
  variations: Variation[];
  sharable_quote: string | null;
}

function flowAreaResponseToFlowArea(weightResponse: WeightResponse[]) {
  return weightResponse.map((wr: WeightResponse) => {
    return {
      flowArea: wr.flow_area,
      weight: wr.weight,
    };
  });
}

function flowAreaToFlowAreaResponse(flowAreaWeights: Session.Weight[]) {
  return flowAreaWeights.map((weight: Session.Weight) => {
    return {
      flow_area: weight.flowArea,
      weight: weight.weight,
    };
  });
}

function variationResposeToVariation(variation: Variation) {
  return {
    id: variation.id,
    duration: variation.duration,
    fileId: variation.file_id,
    session: variation.session,
    introTimestamp: variation.intro_timestamp,
    outroTimestamp: variation.outro_timestamp,
    status: VariationStatus.NOT_CHANGED,
  };
}

function sessionResponseToSession(sessionResponse: SessionResponse, isUnweighted: boolean) {
  return {
    id: sessionResponse.id,
    title: sessionResponse.title,
    fullDescription: sessionResponse.full_description,
    shortDescription: sessionResponse.short_description,
    mainFlowArea: sessionResponse.main_flow_area,
    language: sessionResponse.lang,
    isIntro: sessionResponse.is_intro,
    isFirst: sessionResponse.is_first,
    paidOnly: sessionResponse.paid_only,
    flowAreaWeights: isUnweighted ? [] : flowAreaResponseToFlowArea(sessionResponse.flow_area_weights),
    variations: sessionResponse.variations.map(variationResposeToVariation),
    sharableQuote: {
      url: sessionResponse.sharable_quote,
      file: null,
    }
  };
}

function sessionPropsToRemoteSessionProps(sessionProps: Session.Props) {
  return {
    title: sessionProps.title,
    full_description: sessionProps.fullDescription,
    short_description: sessionProps.shortDescription,
    lang: sessionProps.language,
    is_intro: sessionProps.isIntro,
    is_first: sessionProps.isFirst,
    paid_only: sessionProps.paidOnly,
    flow_area_weights: flowAreaToFlowAreaResponse(sessionProps.flowAreaWeights),
  };
}

function variationPropsToRemoteVariationProps(variation: Session.Variation) {
  return {
    duration: variation.duration,
    intro_timestamp: variation.introTimestamp,
    outro_timestamp: variation.outroTimestamp,
  };
}

export const getUnweightedSessions = (): Promise<Session.Session[]> =>
  api
    .get('/training/unweightedSessions/')
    .then((res) => res.data.map((sessionResponse: SessionResponse) => sessionResponseToSession(sessionResponse, true)));

export const getSessions = (language: Language): Promise<Session.Session[]> =>
  api
    .get('/training/sessions/', { params: { lang: language } })
    .then((res) =>
      res.data.map((sessionResponse: SessionResponse) => sessionResponseToSession(sessionResponse, false))
    );

export const editSession = (sessionId: number, session: Session.Props): Promise<boolean> =>
  api.patch(`/training/session/${sessionId}/`, sessionPropsToRemoteSessionProps(session)).then((res) => {
    return res.status === 200;
  });

export const createSession = (sessionProps: Session.Props) =>
  api.post('/training/sessions/', sessionPropsToRemoteSessionProps(sessionProps)).then((res) => {
    return res.status === 201;
  });

export const createVariation = async (sessionId: number, variation: Session.Variation) => {
  const result = await api.post(
    `/training/session/${sessionId}/variations`,
    variationPropsToRemoteVariationProps(variation)
  );
  return result.data;
};

export const updateVariation = async (variationId: number, variation: Session.Variation) => {
  await api.patch(`/training/sessionVariation/${variationId}/`, variationPropsToRemoteVariationProps(variation));
};

export const uploadVariationAudio = async (variationId: number, file: File) => {
  await uploadFile(`/training/sessionVariation/${variationId}/`, file);
};

export const getSessionById = (id: number): Promise<Session.Session> =>
  api.get(`/training/session/${id}/`).then((res) => res.data);

export const uploadSessionImage = async (id: number, file: File) => {
  await uploadFile(`/training/session/${id}/`, file);
}

