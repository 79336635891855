import { AreaAction, GET_ALL_AREAS_SUCCESS } from './actions';
import { AreaState } from '../types';

const initialState = {
  areas: [],
};

export const areaReducer = (state = initialState, action: AreaAction): AreaState => {
  switch (action.type) {
    case GET_ALL_AREAS_SUCCESS:
      return { ...state, areas: action.areas };

    default:
      return state;
  }
};
