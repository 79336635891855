export interface Subscription {
  renewalType: string;
  expirationDate: string;
  hasTrialPeriod: boolean;
  autoRenewal: boolean;
}

export enum Interval {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}
