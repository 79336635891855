import React from 'react';
import GoogleLogin from 'react-google-login';
import { Button, CircularProgress, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { LoadingState } from 'state/types';
import { googleAppId } from 'api/config';
import logo from 'assets/images/google.png';

interface Props {
  onPress: Function;
  login?: boolean;
  loadingState: LoadingState;
}

const GoogleLoginButton = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const styles = {
    button: { marginTop: theme.spacing(2), alignSelf: 'stretch', backgroundColor: 'white' },
    loadingIndicator: {
      color: theme.palette.background.default,
    },
    logo: {
      width: 25,
      height: 25,
    },
  };

  const { onPress, loadingState, login } = props;
  return (
    <GoogleLogin
      clientId={googleAppId}
      onSuccess={(result) => onPress(result)}
      onFailure={(result) => onPress(result)}
      cookiePolicy={'single_host_origin'}
      render={(renderProps: { onClick: () => void; disabled?: boolean }) => (
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={styles.button}
          disabled={loadingState === LoadingState.LOADING}
          onClick={renderProps.onClick}
          startIcon={<img alt="" src={logo} style={styles.logo} />}
        >
          {loadingState === LoadingState.LOADING ? (
            <CircularProgress size={24} style={styles.loadingIndicator} />
          ) : (
            t(login ? 'login.withGoogle' : 'register.withGoogle')
          )}
        </Button>
      )}
    />
  );
};

export { GoogleLoginButton };
