import { AxiosResponse } from 'axios';

import { TestUser } from 'types/users';
import api from './config';

export const getTestUsers = (): Promise<TestUser[]> =>
  api.get('/user/testify/').then((res: AxiosResponse) => {
    return res.data;
  });

export const convertToTestUser = (email: string) =>
  api.post('/user/testify/', { email }).then((res: AxiosResponse) => {
    return res.status === 200 || res.status === 204;
  });
