import React from 'react';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { Store, PersistGate, persistor } from 'state';
import MainRouter from 'routes/MainRouter';

import 'App.css';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Trenda-Regular, sans-serif',
    h1: {
      fontFamily: 'Trenda-Bold, sans-serif',
      fontSize: '1.4rem',
      color: '#fefefe',
    },
    h2: {
      fontFamily: 'Trenda-Regular, sans-serif',
      fontSize: '1.4rem',
      color: '#fefefe',
    },
    body2: {
      fontFamily: 'Trenda-Light, sans-serif',
      fontSize: '0.9rem',
      color: '#636363',
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#4fd1a4',
      dark: '#4fd1a4',
    },
    secondary: {
      main: '#89fc9c',
      dark: '#89fc9c',
    },
    background: {
      default: '#2e2e2e',
      paper: '#2e2e2e',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={Store}>
        <PersistGate persistor={persistor}>
          <MainRouter />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
