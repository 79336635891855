export const en = {
  translation: {
    menu: {
      account: 'Account',
      logout: 'Logout',
      register: 'Register',
      login: 'Login',
    },
    landing: {
      title: 'Find your flow with Flow Lab',
      bullet11: 'Get access to ',
      bullet12: 'all flow sessions offline',
      bullet21: 'Train regularly with ',
      bullet22: 'your individual weekly schedule',
      bullet31: 'Track your progress ',
      bullet32: 'on your way to more flow',
      register: 'Register',
      login: 'Login',
    },
    verifyEmail: {
      success: 'Your account has been activated!',
      error: "We've encountered an error.",
      tryAgain: 'Please try again.',
    },
    login: {
      title: 'Login',
      email: 'Email',
      password: 'Password',
      button: 'Login',
      withFacebook: 'Sign in with Facebook',
      withGoogle: 'Sign in with Google',
      wrongCredentials: 'Wrong email or password',
      socialError: 'Something went wrong',
    },
    register: {
      title: 'Register',
      email: 'Email',
      password: 'Passwort',
      button: 'Register',
      withFacebook: 'Sign up with Facebook',
      withGoogle: 'Sign up with Google',
      socialError: 'Something went wrong',
      gender: {
        male: 'male',
        female: 'female',
        diverse: 'diverse',
        noAnswer: 'n/a',
      },
    },
    resetPassword: {
      title: 'Reset Password',
      passwordInputLabel: 'New password',
      repeatPasswordInputLabel: 'Repeat new password',
      notMatchingError: 'Passwords do not match',
      backendError: 'Something went wrong',
      submitButton: 'Change Password',
      success: 'Your password has been changed!',
    },
    account: {
      yourSubscription: 'Your Subscription',
      willRenewOn: 'Will renew on {{date}}',
      willExpireOn: 'Will expire on {{date}}',
      enterPromoCode: 'Got a promo code?',
      promoCode: 'Enter code',
      resendCode: 'Resend code',
      yourBenefit: 'Your benefit:',
      submitPromoCode: 'Redeem',
      confirmEmailDialogTitle: 'Confirm Email',
      confirmEmailDialogMessage: 'Please confirm your email address before using a promo code.',
      congratulationsDialogTitle: 'Congratulations',
      congratulationsDialogMessageDiscount: 'Go to the Flow Lab app to redeem your discounted subscription now.',
      congratulationsDialogMessageSubscription: "You've unlocked Flow Lab Pro.",
      welcomeToFlowLab: 'Welcome to Flow Lab - your \n personal mental fitness coach!',
      downloadAndStart: 'Download our app and start your \n journey today.',
    },
    footer: {
      imprint: 'Imprint',
      privacyPolicy: 'Privacy Policy',
      toc: 'Terms & Conditions',
    },
  },
};
