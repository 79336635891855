import { AppState } from '../types';

export const getIsAuthenticated = (appState: AppState) => appState.user.isAuthenticated;

export const getIsAdmin = (appState: AppState) => appState.user.userProperties?.isAdmin;

export const getAuthToken = (appState: AppState) => appState.user.token;

export const getSubscriptions = (appState: AppState) => appState.user.subscriptions;

export const getUserProperties = (appState: AppState) => appState.user.userProperties;

export const getEmailVerified = (appState: AppState) => appState.user.emailVerified;
