import { Notification } from 'types';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';

export interface GetNotificationsStart {
  type: typeof GET_NOTIFICATIONS_START;
}

export const getNotificationsStart = (): GetNotificationsStart => ({
  type: GET_NOTIFICATIONS_START,
});

export interface GetNotificationsSuccess {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  notifications: Notification.Notification[];
}

export const getNotificationsSuccess = (notifications: Notification.Notification[]): GetNotificationsSuccess => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  notifications,
});

export interface GetNotificationsError {
  type: typeof GET_NOTIFICATIONS_ERROR;
  message: string;
}

export const getNotificationsError = (message: string): GetNotificationsError => ({
  type: GET_NOTIFICATIONS_ERROR,
  message,
});

export const EDIT_NOTIFICATION_START = 'EDIT_NOTIFICATION_START';
export const EDIT_NOTIFICATION_SUCCESS = 'EDIT_NOTIFICATION_SUCCESS';
export const EDIT_NOTIFICATION_ERROR = 'EDIT_NOTIFICATION_ERROR';

export interface EditNotificationStart {
  type: typeof EDIT_NOTIFICATION_START;
}

export const editNotificationStart = (): EditNotificationStart => ({
  type: EDIT_NOTIFICATION_START,
});

export interface EditNotificationSuccess {
  type: typeof EDIT_NOTIFICATION_SUCCESS;
  notificationId: number;
  notificationProps: Notification.Props;
}

export const editNotificationSuccess = (
  notificationId: number,
  notificationProps: Notification.Props
): EditNotificationSuccess => ({
  type: EDIT_NOTIFICATION_SUCCESS,
  notificationId,
  notificationProps,
});

export interface EditNotificationError {
  type: typeof EDIT_NOTIFICATION_ERROR;
  message: string;
}

export const editNotificationError = (message: string): EditNotificationError => ({
  type: EDIT_NOTIFICATION_ERROR,
  message,
});

export const CREATE_NOTIFICATION_START = 'CREATE_NOTIFICATION_START';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_ERROR = 'CREATE_NOTIFICATION_ERROR';

export interface CreateNotificationStart {
  type: typeof CREATE_NOTIFICATION_START;
}

export const createNotificationStart = (): CreateNotificationStart => ({
  type: CREATE_NOTIFICATION_START,
});

export interface CreateNotificationSuccess {
  type: typeof CREATE_NOTIFICATION_SUCCESS;
  notifications: Notification.Notification[];
}

export const createNotificationSuccess = (notifications: Notification.Notification[]): CreateNotificationSuccess => ({
  type: CREATE_NOTIFICATION_SUCCESS,
  notifications,
});

export interface CreateNotificationError {
  type: typeof CREATE_NOTIFICATION_ERROR;
  message: string;
}

export const createNotificationError = (message: string): CreateNotificationError => ({
  type: CREATE_NOTIFICATION_ERROR,
  message,
});

export type NotificationAction =
  | GetNotificationsStart
  | GetNotificationsSuccess
  | GetNotificationsError
  | EditNotificationStart
  | EditNotificationSuccess
  | EditNotificationError
  | CreateNotificationStart
  | CreateNotificationSuccess
  | CreateNotificationError;
