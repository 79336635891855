import { Area } from 'types';

import api from './config';

interface AreaResponse {
  id: number;
  name: string;
  description: string;
  background_image_url_male_portrait: string | null;
  background_image_url_female_portrait: string | null;
  background_image_url_male_landscape: string | null;
  background_image_url_female_landscape: string | null;
  background_icon_url_web_app: string | null;
  background_icon_url_mobile_app: string | null;
}

export const getAllAreas = (): Promise<Area.Area[]> =>
  api.get('/areas/').then((res) => {
    const areasResponse = res.data;

    return areasResponse.map((areaResponse: AreaResponse) => {
      return {
        id: areaResponse.id,
        name: areaResponse.name,
        description: areaResponse.description,
        backgroundImageUrlMalePortrait: areaResponse.background_image_url_male_portrait,
        backgroundImageUrlFemalePortrait: areaResponse.background_image_url_female_portrait,
        backgroundImageUrlMaleLandscape: areaResponse.background_image_url_male_landscape,
        backgroundImageUrlFemaleLandscape: areaResponse.background_image_url_female_landscape,
        backgroundIconUrlWebApp: areaResponse.background_icon_url_web_app,
        backgroundIconUrlMobileApp: areaResponse.background_icon_url_mobile_app,
      };
    });
  });
