import * as React from 'react';

import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Statistics } from 'types';

interface Props {
  data: Statistics.TableData;
}

const TableDataHeader = styled('th')({
  textAlign: 'center',
  padding: 0,
  margin: 0,
  fontSize: '0.8rem',
  color: 'white',
});

const TableDataValue = styled('td')({
  height: 50,
  width: 50,
  textAlign: 'center',
  verticalAlign: 'center',
  background: 'linear-gradient(to top right, #4fd1a4, #89fc9c)',
  color: 'black',
});

const TableDataLeadingItem = styled('td')({
  textAlign: 'center',
  padding: 0,
  margin: 0,
  fontSize: '0.8rem',
  color: 'white',
});

function calculateValue(value: number, values: number[]) {
  if (value === 0) {
    return 0;
  } else {
    return ((value / values[1]) * 100).toFixed(1);
  }
}

export class Table extends React.PureComponent<Props> {
  render() {
    return (
      <table>
        <thead>
          <tr>
            {this.props.data[0].map((item, index) => (
              <TableDataHeader key={index}>
                <Typography variant="caption">{item}</Typography>
              </TableDataHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.props.data.slice(1, this.props.data.length).map((item) => (
            <tr key={item.toString()}>
              {item.map((value, index, values) =>
                index === 0 ? (
                  <TableDataLeadingItem key={index.toString()}>
                    <Typography variant="caption">{value}</Typography>
                  </TableDataLeadingItem>
                ) : (
                  <TableDataValue
                    key={index.toString()}
                    style={{
                      background: index === 1 ? 'gray' : undefined,
                      opacity:
                        (values as number[])[1] === 0 ? 0.2 : 0.2 + ((value as number) / (values as number[])[1]) * 0.8,
                    }}
                  >
                    <Typography variant="body1">{value}</Typography>
                    {index > 1 && (
                      <Typography variant="caption">
                        {calculateValue(value as number, values as number[]) + '%'}
                      </Typography>
                    )}
                  </TableDataValue>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
