import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, Button, Box, Typography } from '@material-ui/core';

import api from 'api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function ConfirmEmailWarningDialog(props: Props) {
  const { t } = useTranslation();

  const resendCode = () => {
    api.user.resendConfirmationLink();
    props.onClose();
  };

  return (
    <Dialog onClose={props.onClose} open={props.isOpen}>
      <DialogTitle>{t('account.confirmEmailDialogTitle')}</DialogTitle>
      <Box mx={2} mb={2} display="flex" flexDirection="column">
        <Typography variant="body1">{t('account.confirmEmailDialogMessage')}</Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 16, marginRight: 8, alignSelf: 'flex-end' }}
            onClick={resendCode}
          >
            {t('account.resendCode')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: 16, alignSelf: 'flex-end' }}
            onClick={props.onClose}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
