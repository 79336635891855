import _ from 'lodash';

import * as React from 'react';

import { connect } from 'react-redux';

import { Grid, Typography, Box, Chip, Paper, CircularProgress, Container } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { thunks } from 'state';

import { Statistics } from 'types';

import { AppState, AppDispatch, LoadingState, StatisticState } from 'state/types';
import { Table, Chart } from 'components';

interface PropsFromState {
  endpoints: string[];
  statistics: {
    [enpoint: string]: StatisticState;
  };
}

interface DispatchProps {
  getStatisticsEndpoints: () => void;
  getStatistic: (endpoint: string) => void;
}

function isLoadComplete(endpoint: string, statistics: { [endpoint: string]: StatisticState }) {
  return _.has(statistics, endpoint) && statistics[endpoint].loading === LoadingState.SUCCESS;
}

function isLoadingInProgress(endpoint: string, statistics: { [endpoint: string]: StatisticState }) {
  return _.has(statistics, endpoint) && statistics[endpoint].loading === LoadingState.LOADING;
}

type Props = PropsFromState & DispatchProps & WithStyles;

const styles = {
  loadingStateContainer: {
    marginTop: 24,
  },
  endpointChip: {
    margin: 4,
  },
  graphTableContainer: {
    marginTop: 16,
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  keyValueContainer: {
    marginTop: 16,
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  title: {
    alignSelf: 'flex-start',
    padding: 8,
  },
};

interface State {
  createDialogOpen: boolean;
  createLoadingState: LoadingState;
}

class StatisticsPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      createDialogOpen: false,
      createLoadingState: LoadingState.IDLE,
    };
  }

  componentDidMount() {
    this.props.getStatisticsEndpoints();
  }

  showNew = () => {
    this.setState({
      createDialogOpen: true,
    });
  };

  renderLoadingState() {
    return _.sortBy(this.props.endpoints).map((endpoint: string) => {
      if (isLoadComplete(endpoint, this.props.statistics)) {
        return (
          <Chip
            color="primary"
            className={this.props.classes.endpointChip}
            key={endpoint}
            label={endpoint.replace(/\//g, '')}
          />
        );
      }

      if (isLoadingInProgress(endpoint, this.props.statistics)) {
        return (
          <Chip
            color="secondary"
            className={this.props.classes.endpointChip}
            key={endpoint}
            label={endpoint.replace(/\//g, '')}
          />
        );
      }

      return (
        <Chip
          className={this.props.classes.endpointChip}
          key={endpoint}
          label={endpoint.replace(/\//g, '')}
          onClick={() => this.props.getStatistic(endpoint)}
        />
      );
    });
  }

  renderGraphStatistic(statistic: Statistics.GraphStatistic) {
    return (
      <Grid key={statistic.title} item className={this.props.classes.statisticGridItem} xs={6}>
        <Paper className={this.props.classes.graphTableContainer}>
          <Typography className={this.props.classes.title} variant="h5">
            {statistic.title}
          </Typography>
          <Chart data={statistic.data} />
        </Paper>
      </Grid>
    );
  }

  renderTableStatistic(statistic: Statistics.TableStatistic) {
    return (
      <Grid key={statistic.title} item className={this.props.classes.statisticGridItem} xs={6}>
        <Paper className={this.props.classes.graphTableContainer}>
          <Typography className={this.props.classes.title} variant="h5">
            {statistic.title}
          </Typography>
          <Table data={statistic.data} />
        </Paper>
      </Grid>
    );
  }

  renderKeyValueStatistic(statistic: Statistics.KeyValueStatistic) {
    return (
      <Grid key={statistic.title} item xs={6}>
        <Paper className={this.props.classes.keyValueContainer}>
          <Typography className={this.props.classes.title} variant="h5">
            {statistic.title}
          </Typography>
          <Box mx={2}>
            {statistic.data.map((keyValue: Statistics.KeyValueElement) => {
              const keys = _.keys(keyValue);
              return keys.map((key: string) => {
                return <Typography key={key}>{`${key}: ${keyValue[key]}`}</Typography>;
              });
            })}
          </Box>
        </Paper>
      </Grid>
    );
  }

  renderStatistic = (statistic: StatisticState) => {
    switch (statistic.data.type) {
      case Statistics.Type.GRAPH:
        return this.renderGraphStatistic(statistic.data);
      case Statistics.Type.TABLE:
        return this.renderTableStatistic(statistic.data);
      case Statistics.Type.KEY_VALUE:
        return this.renderKeyValueStatistic(statistic.data);
    }
  };

  renderStatistics() {
    const preparedData = _.filter(this.props.statistics, (statistic) => statistic.loading === LoadingState.SUCCESS);
    return _.values(_.sortBy(preparedData, (statistic) => statistic.data.title)).map(this.renderStatistic);
  }

  render() {
    if (!this.props.endpoints) {
      return <CircularProgress />;
    }

    return (
      <Container>
        <Paper className={this.props.classes.loadingStateContainer}>
          <Box p={1}>{this.renderLoadingState()}</Box>
        </Paper>
        <Grid spacing={3} container>
          {this.renderStatistics()}
        </Grid>
      </Container>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    endpoints: state.statistics.endpoints,
    statistics: state.statistics.data,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    getStatisticsEndpoints: () => dispatch(thunks.statistics.getStatisticsEndpoints()),
    getStatistic: (endpoint: string) => dispatch(thunks.statistics.getStatistic(endpoint)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatisticsPage));
