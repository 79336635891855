import * as React from 'react';

import { connect } from 'react-redux';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Container, Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { thunks } from 'state';
import { AppState, AppDispatch } from 'state/types';

import { NewButton, DialogMode, NotificationListItem, EditCreateNotificationDialog } from 'components';

import { Notification, Language } from 'types';

function filterNotifications(notifications: Notification.Notification[], language: Language, type: Notification.Type) {
  return notifications.filter((notification: Notification.Notification) => {
    return notification.language === language && notification.type === type;
  });
}

const styles = {
  languageSelectControl: {
    minWidth: 120,
  },
  typeSelectControl: {
    minWidth: 200,
  },
};

const NEW_NOTIFICATION_PROPS = {
  title: '',
  body: '',
  language: Language.DE,
  type: Notification.Type.DAILY_REMINDER,
};

interface PropsFromState {
  notifications: Notification.Notification[];
}

interface DispatchProps {
  getNotifications: () => void;
  editNotification: (id: number, props: Notification.Props) => void;
  createNotification: (props: Notification.Props) => void;
}

interface State {
  filterLanguage: Language;
  filterType: Notification.Type;
  createEditOpen: boolean;
  createEditMode: DialogMode;
  editCreateProps: Notification.Props;
}

type Props = PropsFromState & DispatchProps & WithStyles;

class NotificationsPage extends React.PureComponent<Props, State> {
  editNotificationId: number | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      filterLanguage: Language.DE,
      filterType: Notification.Type.DAILY_REMINDER,
      createEditOpen: false,
      createEditMode: DialogMode.EDIT,
      editCreateProps: NEW_NOTIFICATION_PROPS,
    };
  }

  componentDidMount() {
    this.props.getNotifications();
  }

  onLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      filterLanguage: event.target.value as Language,
    });
  };

  onTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      filterType: event.target.value as Notification.Type,
    });
  };

  cancelEditCreate = () => {
    this.editNotificationId = null;

    this.setState({
      createEditOpen: false,
    });
  };

  renderNotifications() {
    return filterNotifications(this.props.notifications, this.state.filterLanguage, this.state.filterType).map(
      (notification: Notification.Notification) => {
        return <NotificationListItem key={notification.id} onEdit={this.showEdit} notification={notification} />;
      }
    );
  }

  showNew = () => {
    this.setState({
      createEditOpen: true,
      createEditMode: DialogMode.CREATE,
      editCreateProps: {
        ...NEW_NOTIFICATION_PROPS,
        type: this.state.filterType,
        language: this.state.filterLanguage,
      },
    });
  };

  showEdit = (notificationId: number) => {
    const notification = this.props.notifications.find(
      (notification: Notification.Notification) => notification.id === notificationId
    );

    if (notification) {
      this.editNotificationId = notification.id;

      this.setState({
        createEditOpen: true,
        createEditMode: DialogMode.EDIT,
        editCreateProps: notification,
      });
    }
  };

  submitEditCreate = (editCreateProps: Notification.Props) => {
    if (this.state.createEditMode === DialogMode.EDIT) {
      if (this.editNotificationId !== null) {
        this.props.editNotification(this.editNotificationId, editCreateProps);
      }
    } else if (this.state.createEditMode === DialogMode.CREATE) {
      this.props.createNotification(editCreateProps);
    }

    this.setState({
      createEditOpen: false,
    });
  };

  render() {
    return (
      <Container>
        <Box ml={3} my={4} display="flex">
          <FormControl className={this.props.classes.languageSelectControl}>
            <InputLabel id="language-input-label">Language</InputLabel>
            <Select id="language-select" value={this.state.filterLanguage} onChange={this.onLanguageChange}>
              <MenuItem value={Language.DE}>German</MenuItem>
              <MenuItem value={Language.EN}>English</MenuItem>
            </Select>
          </FormControl>
          <Box ml={3}>
            <FormControl className={this.props.classes.typeSelectControl}>
              <InputLabel id="language-input-label">Type</InputLabel>
              <Select id="language-select" value={this.state.filterType} onChange={this.onTypeChange}>
                  <MenuItem value={Notification.Type.DAILY_REMINDER}>Daily Reminder</MenuItem>
                  <MenuItem value={Notification.Type.ACTIVATION}>Activation</MenuItem>
                  <MenuItem value={Notification.Type.PROMOTIONAL_FIRST}>Promotional First</MenuItem>
                  <MenuItem value={Notification.Type.PROMOTIONAL_SECOND}>Promotional Second</MenuItem>
                  <MenuItem value={Notification.Type.COMPLETED_SCHEDULE}>Completed Schedule</MenuItem>
                  <MenuItem value={Notification.Type.INACTIVE_USER}>Inactive User</MenuItem>
                  <MenuItem value={Notification.Type.ONE_SESSION_LEFT}>One Session Left</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        {this.renderNotifications()}
        <NewButton onClick={this.showNew} variant="contained" color="primary">
          New
        </NewButton>
        <EditCreateNotificationDialog
          isOpen={this.state.createEditOpen}
          mode={this.state.createEditMode}
          onSubmit={this.submitEditCreate}
          onCancel={this.cancelEditCreate}
          notificationProps={this.state.editCreateProps}
        />
      </Container>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    notifications: state.notification.notifications,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    getNotifications: () => dispatch(thunks.notification.getNotifications()),
    editNotification: (notificationId: number, notificationProps: Notification.Props) =>
      dispatch(thunks.notification.editNotification(notificationId, notificationProps)),
    createNotification: (notificationProps: Notification.Props) =>
      dispatch(thunks.notification.createNotification(notificationProps)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationsPage));
