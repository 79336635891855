import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuthToken } from 'state/user/selectors';
import { logout } from 'state/user/thunks';
import { Store } from 'state';
import { AppState } from 'state/types';

const backEndUrl = process.env.REACT_APP_BACKEND_URL;
const protocol = 'https';
const fallBackDevUrl = 'api-dev.flowlab.com';
const fallBackProductionUrl = 'api-prod.flowlab.com';

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
const googleAppId = process.env.REACT_APP_GOOGLE_APP_ID || '';

function getFallback() {
  switch (process.env.NODE_ENV) {
    case 'production':
      return fallBackProductionUrl;

    case 'development':
      return fallBackDevUrl;

    default:
      return fallBackProductionUrl;
  }
}

const jwtHeader = 'JWT-FLOWLETICS ';

const refreshAuthenticationUrl = '/refreshAuthentication/';

const baseURL = `${protocol}://${backEndUrl != null ? backEndUrl : getFallback()}`;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.defaults.headers.put['Content-Type'] = 'application/json; charset=utf-8';

// jwt auth token refresh on every request when logged in
axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  // @ts-ignore
  const state = Store.getState() as AppState;

  const token = getAuthToken(state);

  if (config.url !== refreshAuthenticationUrl && token) {
    config.headers.Authorization = jwtHeader + token;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const {
      response: { status },
    } = error;

    if (status === 401) {
      // @ts-ignore
      Store.dispatch(logout());
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;

export { jwtHeader, refreshAuthenticationUrl, baseURL, facebookAppId, googleAppId };
