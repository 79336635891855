export enum QuestionType {
  AREA_RELATED = 'area_related',
  STANDARD = 'standard',
}

export enum SurveyType {
  PERSONAGE_CHECK = 'personage_check',
  FLOW_CHECK = 'flow_check',
  GENERAL_SURVEY = 'general_survey',
}

export interface CreateQuestionAreaRelated {
  questionType: QuestionType;
  text: string;
  areaId: number;
  evaluateInverse: boolean;
}

export interface CreateQuestionStandard {
  questionType: QuestionType;
  text: string;
}

export type CreateQuestion = CreateQuestionAreaRelated | CreateQuestionStandard;

export enum Language {
  EN = 'en',
  DE = 'de',
}

export interface CreateSurvey {
  language: Language;
  surveyType: SurveyType;
  questions: CreateQuestion[];
  questionPrompt: string;
}

export function isQuestionAreaRelated(question: CreateQuestion): question is CreateQuestionAreaRelated {
  return question.questionType === QuestionType.AREA_RELATED;
}
