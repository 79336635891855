import { TestUser } from 'types/users';

export const GET_TEST_USERS_START = 'GET_TEST_USERS_START';
export const GET_TEST_USERS_SUCCESS = 'GET_TEST_USERS_SUCCESS';
export const GET_TEST_USERS_ERROR = 'GET_TEST_USERS_ERROR';

export interface GetTestUsersStart {
  type: typeof GET_TEST_USERS_START;
}

export const getTestUsersStart = (): GetTestUsersStart => ({
  type: GET_TEST_USERS_START,
});

export interface GetTestUsersSuccess {
  type: typeof GET_TEST_USERS_SUCCESS;
  testUsers: TestUser[];
}

export const getTestUsersSuccess = (testUsers: TestUser[]): GetTestUsersSuccess => ({
  type: GET_TEST_USERS_SUCCESS,
  testUsers,
});

export interface GetTestUsersError {
  type: typeof GET_TEST_USERS_ERROR;
  message: string;
}

export const getTestUsersError = (message: string): GetTestUsersError => ({
  type: GET_TEST_USERS_ERROR,
  message,
});

export interface GetTestUsersStart {
  type: typeof GET_TEST_USERS_START;
}

export const CONVERT_TO_TEST_USER_START = 'CONVERT_TO_TEST_USER_START';
export const CONVERT_TO_TEST_USER_SUCCESS = 'CONVERT_TO_TEST_USER_SUCCESS';
export const CONVERT_TO_TEST_USER_ERROR = 'CONVERT_TO_TEST_USER_ERROR';

export interface ConvertToTestUserStart {
  type: typeof CONVERT_TO_TEST_USER_START;
}

export const convertToTestUserStart = (): ConvertToTestUserStart => ({
  type: CONVERT_TO_TEST_USER_START,
});

export interface ConvertToTestUserSuccess {
  type: typeof CONVERT_TO_TEST_USER_SUCCESS;
}

export const convertToTestUserSuccess = (): ConvertToTestUserSuccess => ({
  type: CONVERT_TO_TEST_USER_SUCCESS,
});

export interface ConvertToTestUserError {
  type: typeof CONVERT_TO_TEST_USER_ERROR;
  message: string;
}

export const convertToTestUserError = (message: string): ConvertToTestUserError => ({
  type: CONVERT_TO_TEST_USER_ERROR,
  message,
});

export type UsersAction =
  | GetTestUsersStart
  | GetTestUsersSuccess
  | GetTestUsersError
  | ConvertToTestUserStart
  | ConvertToTestUserSuccess
  | ConvertToTestUserError;
