import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchAllUserInformation } from 'state/user/thunks';

export function useGetAllUserInformation() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUserInformation());
  }, [dispatch]);
}
