import { ThunkAction } from 'redux-thunk';

import api from 'api';

import { AppState } from '../types';

import * as actions from './actions';

export type AreaThunkAction = ThunkAction<void, AppState, void, actions.AreaAction>;

export function getAllAreas(): AreaThunkAction {
  return async (dispatch) => {
    dispatch(actions.getAllAreasStart());

    try {
      const areas = await api.area.getAllAreas();

      dispatch(actions.getAllAreasSuccess(areas));
    } catch (error) {
      dispatch(actions.getAllAreasError(error.message));
    }
  };
}
