import Axios, { AxiosResponse } from 'axios';

import api from './config';

interface SignedPostDataRequestResponse {
  data: { fields: { [key: string]: string } };
  url: string;
  file_id: number;
  file_name: string;
}

export const uploadFile = async (url: string, file: File) => {
  const { name, size, type } = file;

  const signedPostDataResponse: SignedPostDataRequestResponse = await api
    .post(url, { file_name: name, file_size: size, mime_type: type })
    .then((res) => res.data);

  const postData = new FormData();
  Object.keys(signedPostDataResponse.data.fields).forEach((key: string) => {
    postData.append(key, signedPostDataResponse.data.fields[key]);
  });

  postData.append('file', file, signedPostDataResponse.file_name);

  await Axios.post(signedPostDataResponse.url, postData);

  await api.post(`/file/${signedPostDataResponse.file_id}/`);
};

export const getAudioFileInfo = (fileId: number): Promise<AxiosResponse | null> =>
  api
    .get(`/file/${fileId}/`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch(() => {
      return null;
    });
