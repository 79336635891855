import React, { useState, FormEvent, ChangeEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Box, TextField, CircularProgress, useTheme, Typography, Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getIsAuthenticated, getUserProperties } from 'state/user/selectors';
import { login, authenticate } from 'state/user/thunks';
import { LoadingState } from 'state/types';
import { SocialProvider } from 'types/user';
import { FacebookLoginButton, GoogleLoginButton } from 'components';

const LoginPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      padding: theme.spacing(4),
    },
    button: { marginTop: theme.spacing(1), alignSelf: 'stretch' },
    wrapper: {
      display: 'flex',
      backgroundColor: 'coral',
    },
    innerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute' as 'absolute',
    },
    loadingIndicator: {
      color: theme.palette.background.default,
    },
  };

  const isAuthenticated = useSelector(getIsAuthenticated);
  const userProperties = useSelector(getUserProperties);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);
  const [socialError, setSocialError] = useState(false);

  const onLoginPress = async (event: FormEvent) => {
    event.preventDefault();

    setLoadingState(LoadingState.LOADING);
    try {
      const loginResult = await dispatch(login({ login: email, password }));

      // @ts-ignore
      if (loginResult) {
        setLoadingState(LoadingState.SUCCESS);
      } else {
        setLoadingState(LoadingState.ERROR);
      }
    } catch (e) {
      setLoadingState(LoadingState.ERROR);
    }
  };

  const onSocialLoginPress = async (accessToken: string, provider: SocialProvider) => {
    setLoadingState(LoadingState.LOADING);
    try {
      const registerResult = await dispatch(
        authenticate({
          accessToken,
          provider,
          preferredLanguage: i18n.language.slice(0, 2),
        })
      );

      // @ts-ignore
      if (registerResult) {
        setLoadingState(LoadingState.SUCCESS);
      } else {
        setLoadingState(LoadingState.IDLE);
        setSocialError(true);
      }
    } catch (e) {
      setLoadingState(LoadingState.IDLE);
      setSocialError(true);
    }
  };

  const onFacebookLoginPress = async ({ accessToken }: { accessToken: string }) => {
    await onSocialLoginPress(accessToken, SocialProvider.FACEBOOK);
  };

  const onGoogleLoginPress = async ({ accessToken, error }: { accessToken: string; error: string; }) => {
    if (['popup_closed_by_user', 'idpiframe_initialization_failed'].includes(error)) return;

    await onSocialLoginPress(accessToken, SocialProvider.GOOGLE);
  };

  if (isAuthenticated) {
    if (userProperties && userProperties.isAdmin) {
      return <Redirect to="/admin/sessions" />;
    } else {
      return <Redirect to="/account" />;
    }
  } else {
    return (
      <Container style={{ flex: 1, display: 'flex' }} maxWidth="sm" color="background">
        <Box flex={1} display="flex" flexDirection="column">
          <Box flex={2 / 3} display="flex" flexDirection="column" justifyContent="center" my={4}>
            <Card variant="outlined" style={{ borderWidth: 2, borderColor: '#4fd1a4' }}>
              <form style={styles.container} onSubmit={onLoginPress}>
                <Typography variant="h5" gutterBottom color="primary">
                  {t('login.title')}
                </Typography>
                <TextField
                  label={t('login.email')}
                  value={email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setLoadingState(LoadingState.IDLE);
                    setEmail(event.target.value);
                    setSocialError(false);
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label={t('login.password')}
                  value={password}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setLoadingState(LoadingState.IDLE);
                    setPassword(event.target.value);
                    setSocialError(false);
                  }}
                  margin="normal"
                  variant="outlined"
                  type="password"
                />

                <Box display="flex" alignItems="center" style={{ height: 24 }}>
                  {loadingState === LoadingState.ERROR && (
                    <Typography color="error">{t('login.wrongCredentials')}</Typography>
                  )}
                  {socialError && (
                    <Typography color="error">{t('login.socialError')}</Typography>
                  )}
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={styles.button}
                  disabled={loadingState === LoadingState.LOADING || loadingState === LoadingState.ERROR}
                >
                  {loadingState === LoadingState.LOADING ? (
                    <CircularProgress size={24} style={styles.loadingIndicator} />
                  ) : (
                    t('login.button')
                  )}
                </Button>
                <FacebookLoginButton onPress={onFacebookLoginPress} loadingState={loadingState} login />
                <GoogleLoginButton onPress={onGoogleLoginPress} loadingState={loadingState} login />
              </form>
            </Card>
          </Box>
        </Box>
      </Container>
    );
  }
};

export default LoginPage;
