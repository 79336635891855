import _ from 'lodash';
import moment from 'moment';

import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, useTheme, Button, TextField, Container, Box, Card, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getSubscriptions } from 'state/user/selectors';
import { useGetAllUserInformation } from 'state/user/hooks';
import { useTranslation } from 'react-i18next';
import { CongratulationsDialog, ConfirmEmailWarningDialog, StoreLinkFooter } from 'components';

import { LoadingState } from 'state/types';
import { getSubscriptions as getSubscriptionsAction } from 'state/user/thunks';
import api from 'api';
import BackgroundImage from 'assets/images/swoosh.png';
import BackgroundImageSmall from 'assets/images/swoosh_sm.png';
import CompanyImage from 'assets/images/comp.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    backgroundContainer: {
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#242424',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${BackgroundImageSmall})`,
        backgroundSize: 'cover',
      },
      [theme.breakpoints.up('md')]: {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
      },
    },
    company: {
      [theme.breakpoints.down('sm')]: {
        height: 150,
        width: 164,
        marginTop: 0,
      },
      [theme.breakpoints.up('sm')]: {
        height: 250,
        width: 272,
        marginTop: 48,
      },
    },
    storeButtons: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
        marginBottom: 16,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
        marginBottom: 32,
      },
    },
    welcomeText: {
      whiteSpace: 'pre-line',
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.h5,
        marginTop: 10,
        lineHeight: 1,
      },
      [theme.breakpoints.up('sm')]: {
        ...theme.typography.h4,
      },
    },
    downloadText: {
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.h6,
        whiteSpace: 'pre-line',
        lineHeight: 1,
        marginTop: 5,
      },
      [theme.breakpoints.up('sm')]: {
        ...theme.typography.h1,
        marginTop: 10,
      },
    },
    enterPromoCode: {
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.body1,
        marginTop: 0,
      },
      [theme.breakpoints.up('sm')]: {
        ...theme.typography.h5,
        marginTop: 10,
      },
    },
  })
);

const AccountPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const subscriptions = useSelector(getSubscriptions);
  const dispatch = useDispatch();
  const classes = useStyles();

  useGetAllUserInformation();

  const styles = {
    button: { marginTop: theme.spacing(2), alignSelf: 'center' },
    loadingIndicator: {},
    company: { height: 250, width: 272 },
    rootContainer: { paddingRight: 0, paddingLeft: 0 },
  };

  const [promoCode, setPromoCode] = React.useState('');
  const [benefitString, setBenefitString] = React.useState('');
  const [showNotConfirmedDialog, setShowNotConfirmedDialog] = React.useState(false);
  const [showCongratsDialog, setShowCongratsDialog] = React.useState(false);
  const [promoLoadingState, setPromoLoadingState] = React.useState(LoadingState.IDLE);
  const [isSubscriptionCode, setIsSubscriptionCode] = React.useState(false);

  const activeSubscription =
    subscriptions && subscriptions.find((subscription) => moment(subscription.expirationDate).isAfter(moment(), 'd'));

  const useCode = async () => {
    setPromoLoadingState(LoadingState.LOADING);
    const userProperties = await api.user.fetchUserProperties();

    if (userProperties.isConfirmed) {
      const result = await api.promotion.useCode(promoCode);

      if (result) {
        setShowCongratsDialog(true);

        setPromoCode('');

        dispatch(getSubscriptionsAction());
      }
    } else {
      setShowNotConfirmedDialog(true);
    }
  };

  const onPromoCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value);
    checkCode.current(event.target.value);
  };

  const checkCode = React.useRef(
    _.throttle(async (code: string) => {
      setPromoLoadingState(LoadingState.LOADING);
      setBenefitString('');
      const result = await api.promotion.getCodeDetails(code);

      if (result) {
        setBenefitString(result.benefit);
        setIsSubscriptionCode(!result.products);
      }

      setPromoLoadingState(LoadingState.SUCCESS);
    }, 1000)
  );

  const renderExistingSubscription = () => {
    if (activeSubscription) {
      const expireRenewLabelId = activeSubscription.autoRenewal ? 'account.willRenewOn' : 'account.willExpireOn';
      return (
        <Box m={2}>
          <Typography variant="h5" gutterBottom color="textPrimary">
            {t('account.yourSubscription')}
          </Typography>
          <Typography variant="h6" gutterBottom color="textPrimary">
            {t(expireRenewLabelId, { date: moment(activeSubscription.expirationDate).format('l') })}
          </Typography>
        </Box>
      );
    }
  };

  const renderPromoStatus = () => {
    if (benefitString !== '') {
      return (
        <>
          <Typography align="center">{t('account.yourBenefit')}</Typography>
          <Typography align="center" color="primary">
            {benefitString}
          </Typography>
        </>
      );
    } else if (promoLoadingState === LoadingState.LOADING) {
      return <CircularProgress size={24} style={styles.loadingIndicator} />;
    } else {
      return null;
    }
  };

  const renderPromoEntry = () => {
    return (
      <Box m={2} alignItems="center" display="flex" flexDirection="column">
        <Typography align="center" color="textSecondary" className={classes.enterPromoCode}>
          {t('account.enterPromoCode')}
        </Typography>
        <TextField
          label={t('account.promoCode')}
          value={promoCode}
          onChange={onPromoCodeChanged}
          margin="normal"
          variant="outlined"
          fullWidth
        />
        <Box mt={1} style={{ height: 44 }}>
          {renderPromoStatus()}
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={styles.button}
          disabled={benefitString === ''}
          onClick={useCode}
        >
          {t('account.submitPromoCode')}
        </Button>
      </Box>
    );
  };

  return (
    <Container maxWidth={false} style={styles.rootContainer}>
      <Box className={classes.backgroundContainer}>
        <Container style={{ flex: 1, display: 'flex' }} maxWidth="sm" color="background">
          <Box flex={1} display="flex" flexDirection="column">
            <Box flex={1} display="flex" flexDirection="column" justifyContent="center" my={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <img alt="" src={CompanyImage} className={classes.company} />
                <Typography align="center" color="textPrimary" className={classes.welcomeText}>
                  {t('account.welcomeToFlowLab')}
                </Typography>
                <Typography align="center" className={classes.downloadText} color="textSecondary">
                  {t('account.downloadAndStart')}
                </Typography>
              </Box>
              <Box className={classes.storeButtons}>
                <StoreLinkFooter />
              </Box>
              <Card variant="outlined">
                {activeSubscription !== undefined ? renderExistingSubscription() : renderPromoEntry()}
              </Card>
            </Box>
          </Box>
          <ConfirmEmailWarningDialog isOpen={showNotConfirmedDialog} onClose={() => setShowNotConfirmedDialog(false)} />
          <CongratulationsDialog
            message={
              isSubscriptionCode
                ? t('account.congratulationsDialogMessageSubscription')
                : t('account.congratulationsDialogMessageDiscount')
            }
            isOpen={showCongratsDialog}
            onClose={() => setShowCongratsDialog(false)}
          />
        </Container>
      </Box>
    </Container>
  );
};

export default AccountPage;
