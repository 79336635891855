import {
  UsersAction,
  GET_TEST_USERS_START,
  GET_TEST_USERS_SUCCESS,
  GET_TEST_USERS_ERROR,
  CONVERT_TO_TEST_USER_START,
  CONVERT_TO_TEST_USER_SUCCESS,
  CONVERT_TO_TEST_USER_ERROR,
} from './actions';

import { UsersState, LoadingState } from '../types';

const initialState: UsersState = {
  testUsers: [],
  testUsersLoading: LoadingState.IDLE,
  convertToTestUserLoading: LoadingState.IDLE,
};

export const usersReducer = (state = initialState, action: UsersAction): UsersState => {
  switch (action.type) {
    case GET_TEST_USERS_START:
      return { ...state, testUsersLoading: LoadingState.LOADING };

    case GET_TEST_USERS_SUCCESS:
      return { ...state, testUsersLoading: LoadingState.SUCCESS, testUsers: action.testUsers };

    case GET_TEST_USERS_ERROR:
      return { ...state, testUsersLoading: LoadingState.ERROR };
    case CONVERT_TO_TEST_USER_START:
      return { ...state, convertToTestUserLoading: LoadingState.LOADING };

    case CONVERT_TO_TEST_USER_SUCCESS:
      return { ...state, convertToTestUserLoading: LoadingState.SUCCESS };

    case CONVERT_TO_TEST_USER_ERROR:
      return { ...state, convertToTestUserLoading: LoadingState.ERROR };

    default:
      return state;
  }
};
