import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, Button, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Confetti from 'react-confetti';

const StyledDialog = withStyles({
  paper: {
    minWidth: '30vw',
  },
})(Dialog);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

export function CongratulationsDialog(props: Props) {
  const { t } = useTranslation();

  return (
    <StyledDialog onClose={props.onClose} open={props.isOpen}>
      <Confetti colors={['#fefefe', '#636363', '#4fd1a4', '#89fc9c']} />
      <DialogTitle>{t('account.congratulationsDialogTitle')}</DialogTitle>
      <Box mx={2} mb={2} display="flex" flexDirection="column">
        <Typography variant="body1">{props.message}</Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: 16, alignSelf: 'flex-end' }}
            onClick={props.onClose}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}
