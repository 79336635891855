import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import { userReducer } from './user/reducer';
import { areaReducer } from './area/reducer';
import { sessionReducer } from './session/reducer';
import { notificationReducer } from './notification/reducer';
import { statisticsReducer } from './statistics/reducer';
import { usersReducer } from './users/reducers';

import * as areaSelectors from './area/selectors';
import * as sessionSelectors from './session/selectors';

import * as areaThunks from './area/thunks';
import * as sessionThunks from './session/thunks';
import * as userThunks from './user/thunks';
import * as surveyThunks from './survey/thunks';
import * as notificationThunks from './notification/thunks';
import * as statisticsThunks from './statistics/thunks';
import * as usersThunks from './users/thunks';

export const selectors = {
  area: areaSelectors,
  session: sessionSelectors,
};

export const thunks = {
  area: areaThunks,
  session: sessionThunks,
  user: userThunks,
  survey: surveyThunks,
  notification: notificationThunks,
  statistics: statisticsThunks,
  users: usersThunks,
};

const reducers = {
  user: userReducer,
  area: areaReducer,
  session: sessionReducer,
  notification: notificationReducer,
  statistics: statisticsReducer,
  users: usersReducer,
};

// @ts-ignore
const appReducer = combineReducers(reducers);

const migrations = {};

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  migrate: createMigrate(migrations, { debug: false }),
  version: 1,
};

const persistedReducer = persistReducer(rootPersistConfig, appReducer);

export const Store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export type AppDispatch = typeof Store.dispatch;

export const persistor = persistStore(Store);

export { PersistGate } from 'redux-persist/integration/react';
