import _ from 'lodash';

import { Survey } from 'types';

import api from './config';

export enum AnswerGroupType {
  RANGE = 'range',
  SELECTION = 'selection',
}

export enum AnswerType {
  INT = 'int',
}

interface PossibleAnswerResponse {
  id: number;
  answer_type: AnswerType;
  values: { value: number };
}

interface QuestionResponse {
  id: number;
  values: { text: string };
  possible_answers: PossibleAnswerResponse[];
  answer_group_type: AnswerGroupType;
}

interface SurveyResponse {
  questions: QuestionResponse[];
  id: number;
  question_prefix: string;
}

interface CreatePossibleAnswer {
  answer_type: AnswerType;
  values: { value: number };
}

interface CreateQuestionAreaRelatedValues {
  text: string;
  area_id?: number;
  evaluate_inverse: boolean;
}

interface CreateQuestionStandardValues {
  text: string;
}

interface CreateQuestionAreaRelated {
  question_type: Survey.QuestionType;
  answer_group_type: AnswerGroupType;
  values: CreateQuestionAreaRelatedValues;
  possible_answers: CreatePossibleAnswer[];
}

interface CreateQuestionStandard {
  question_type: Survey.QuestionType;
  answer_group_type: AnswerGroupType;
  values: CreateQuestionStandardValues;
  possible_answers: CreatePossibleAnswer[];
}

type CreateQuestion = CreateQuestionAreaRelated | CreateQuestionStandard;

export interface CreateSurvey {
  survey_type: Survey.SurveyType;
  questions: CreateQuestion[];
  question_prefix?: string;
  lang: Survey.Language;
}

const POSSIBLE_ANSWERS = _.range(1, 8).map((num: number) => {
  return {
    answer_type: AnswerType.INT,
    values: {
      value: num,
    },
  };
});

function questionToBackendQuestion(question: Survey.CreateQuestion): CreateQuestion {
  if (Survey.isQuestionAreaRelated(question)) {
    return {
      question_type: question.questionType,
      answer_group_type: AnswerGroupType.RANGE,
      possible_answers: POSSIBLE_ANSWERS,
      values: {
        text: question.text,
        area_id: question.areaId,
        evaluate_inverse: question.evaluateInverse,
      },
    };
  } else {
    return {
      question_type: question.questionType,
      answer_group_type: AnswerGroupType.RANGE,
      possible_answers: POSSIBLE_ANSWERS,
      values: {
        text: question.text,
      },
    };
  }
}

function createSurveyToBackendCreateSurvey(survey: Survey.CreateSurvey): CreateSurvey {
  return {
    survey_type: survey.surveyType,
    question_prefix: survey.questionPrompt,
    questions: survey.questions.map(questionToBackendQuestion),
    lang: survey.language,
  };
}

export const createSurvey = (newSurvey: Survey.CreateSurvey): Promise<boolean> => {
  const params = createSurveyToBackendCreateSurvey(newSurvey);
  return api.post('/survey/new/', params).then((res) => {
    return res.status === 204;
  });
};

/* const TEST_SURVEY = {
  id: 1,
  surveyType: 'flow_check',
  questionPrefix:
    'Wie sehr trifft die folgende aussage auf dich zu? (flow check))',
  questions: [
    {
      id: 2,
      questionType: 'standard',
      values: {
        text: 'Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow. Ich spüre flow.'
      },
      answerGroupType: 'range',
      possibleAnswers: [
        {
          id: 3,
          answerType: 'int',
          values: {
            value: 0
          }
        },
        {
          id: 4,
          answerType: 'int',
          values: {
            value: 1
          }
        },
        {
          id: 5,
          answerType: 'int',
          values: {
            value: 2
          }
        },
        {
          id: 6,
          answerType: 'int',
          values: {
            value: 3
          }
        },
        {
          id: 7,
          answerType: 'int',
          values: {
            value: 4
          }
        },
        {
          id: 8,
          answerType: 'int',
          values: {
            value: 5
          }
        },
        {
          id: 9,
          answerType: 'int',
          values: {
            value: 6
          }
        },
        {
          id: 10,
          answerType: 'int',
          values: {
            value: 7
          }
        },
        {
          id: 11,
          answerType: 'int',
          values: {
            value: 8
          }
        },
        {
          id: 12,
          answerType: 'int',
          values: {
            value: 9
          }
        },
        {
          id: 13,
          answerType: 'int',
          values: {
            value: 10
          }
        }
      ]
    },
    {
      id: 14,
      questionType: 'standard',
      values: {
        text: 'Ich fühle flow.'
      },
      answerGroupType: 'range',
      possibleAnswers: [
        {
          id: 3,
          answerType: 'int',
          values: {
            value: 0
          }
        },
        {
          id: 4,
          answerType: 'int',
          values: {
            value: 1
          }
        },
        {
          id: 5,
          answerType: 'int',
          values: {
            value: 2
          }
        },
        {
          id: 6,
          answerType: 'int',
          values: {
            value: 3
          }
        },
        {
          id: 7,
          answerType: 'int',
          values: {
            value: 4
          }
        },
        {
          id: 8,
          answerType: 'int',
          values: {
            value: 5
          }
        },
        {
          id: 9,
          answerType: 'int',
          values: {
            value: 6
          }
        },
        {
          id: 10,
          answerType: 'int',
          values: {
            value: 7
          }
        },
        {
          id: 11,
          answerType: 'int',
          values: {
            value: 8
          }
        },
        {
          id: 12,
          answerType: 'int',
          values: {
            value: 9
          }
        },
        {
          id: 13,
          answerType: 'int',
          values: {
            value: 10
          }
        }
      ]
    }
  ]
};
*/
