import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
} from '@material-ui/core';

import { Notification, Language } from 'types';

import TextField from './TextField';
import { DialogMode } from './types';

interface Props {
  isOpen: boolean;
  mode: DialogMode;
  onSubmit: (notificationProps: Notification.Props) => void;
  onCancel: () => void;
  notificationProps: Notification.Props;
}

const useStyles = makeStyles(() =>
  createStyles({
    languageTypeRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    languageSelectControl: {
      minWidth: 120,
    },
    typeSelectControl: {
      minWidth: 200,
    },
  })
);

export function EditCreateNotificationDialog(props: Props) {
  const classes = useStyles();
  const title = props.mode === DialogMode.CREATE ? 'Create Notification' : 'Edit Notification';

  const [notificationProps, setNotificationProps] = React.useState(props.notificationProps);

  React.useEffect(() => {
    setNotificationProps(props.notificationProps);
  }, [props]);

  const onLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNotificationProps({
      ...notificationProps,
      language: event.target.value as Language,
    });
  };

  const onTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNotificationProps({
      ...notificationProps,
      type: event.target.value as Notification.Type,
    });
  };

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationProps({
      ...notificationProps,
      title: event.target.value,
    });
  };

  const onBodyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationProps({
      ...notificationProps,
      body: event.target.value,
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField
          id="title"
          margin="normal"
          label="Title"
          value={notificationProps.title}
          onChange={onTitleChange}
          type="text"
          fullWidth
          required
          variant="outlined"
        />
        <TextField
          id="body"
          margin="normal"
          label="Body"
          value={notificationProps.body}
          onChange={onBodyChange}
          type="text"
          fullWidth
          multiline
          required
          rows="2"
          variant="outlined"
        />
        <Box className={classes.languageTypeRow} display="flex">
          <Box ml={3} my={1} display="flex">
            <FormControl className={classes.languageSelectControl}>
              <InputLabel id="language-input-label">Language</InputLabel>
              <Select id="language-select" value={notificationProps.language} onChange={onLanguageChange}>
                <MenuItem value={Language.DE}>German</MenuItem>
                <MenuItem value={Language.EN}>English</MenuItem>
              </Select>
            </FormControl>
            <Box ml={3}>
              <FormControl className={classes.typeSelectControl}>
                <InputLabel id="language-input-label">Type</InputLabel>
                <Select id="language-select" value={notificationProps.type} onChange={onTypeChange}>
                  <MenuItem value={Notification.Type.DAILY_REMINDER}>Daily Reminder</MenuItem>
                  <MenuItem value={Notification.Type.ACTIVATION}>Activation</MenuItem>
                  <MenuItem value={Notification.Type.PROMOTIONAL_FIRST}>Promotional First</MenuItem>
                  <MenuItem value={Notification.Type.PROMOTIONAL_SECOND}>Promotional Second</MenuItem>
                  <MenuItem value={Notification.Type.COMPLETED_SCHEDULE}>Completed Schedule</MenuItem>
                  <MenuItem value={Notification.Type.INACTIVE_USER}>Inactive User</MenuItem>
                  <MenuItem value={Notification.Type.ONE_SESSION_LEFT}>One Session Left</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() =>
            props.onSubmit({
              title: notificationProps.title,
              body: notificationProps.body,
              language: notificationProps.language,
              type: notificationProps.type,
            })
          }
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
