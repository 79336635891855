import * as React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Divider,
  ExpansionPanel,
  Box,
  Button,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Notification } from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    contentColumn: {
      flexDirection: 'column',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:gover': {
        textDecoration: 'underline',
      },
    },
  })
);

interface Props {
  notification: Notification.Notification;
  onEdit: (sessionId: number) => void;
}

export function NotificationListItem(props: Props) {
  const classes = useStyles();
  const { notification, onEdit } = props;

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
        <div>
          <Typography variant="h6">{notification.title}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <Box className={classes.contentColumn} display="flex">
          <Typography variant="body1">{notification.body}</Typography>
        </Box>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <Button size="small" color="primary" onClick={() => onEdit(notification.id)}>
          Edit
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}
