import { Language } from './common';

export enum Type {
  DAILY_REMINDER = 'daily_reminder',
  ACTIVATION =  'activation',
  PROMOTIONAL_FIRST = 'promotional_first',
  PROMOTIONAL_SECOND = 'promotional_second',
  ONE_SESSION_LEFT = 'one_session_left',
  INACTIVE_USER = 'inactive_user',
  COMPLETED_SCHEDULE = 'completed_schedule',
}

export interface Props {
  title: string;
  body: string;
  type: Type;
  language: Language;
}

export interface Notification extends Props {
  id: number;
}
