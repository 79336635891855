import * as React from 'react';

import { VictoryChart, VictoryArea, VictoryAxis, VictoryScatter, VictoryLine } from 'victory';

import { Statistics } from 'types';

interface Props {
  data: Statistics.GraphData;
}

export class Chart extends React.PureComponent<Props> {
  render() {
    const { data } = this.props;

    return (
      <VictoryChart>
        <VictoryAxis
          animate={{
            duration: 2000,
            easing: 'bounce',
          }}
          style={styles.axis}
        />
        <VictoryAxis
          crossAxis={true}
          dependentAxis={true}
          animate={{
            duration: 2000,
            easing: 'bounce',
          }}
          style={styles.axis}
        />
        <VictoryArea style={styles.chart} data={data} x="x" y="y" />
        <VictoryLine style={styles.line} data={data} x="x" y="y" />
        <VictoryScatter style={styles.scatter} data={data} x="x" y="y" />
        <>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#36E9B9', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#89fc9c', stopOpacity: 1 }} />
          </linearGradient>
        </>
      </VictoryChart>
    );
  }
}

const styles = {
  scatter: {
    data: {
      fill: 'url(#grad1)',
    },
  },
  line: {
    data: {
      stroke: 'url(#grad1)',
    },
  },
  axis: {
    axis: { stroke: '#252525', strokeWidth: 2 },
    grid: {
      stroke: '#252525',
      strokeWidth: 2,
      strokeDasharray: '5 2.5',
    },
    tickLabels: {
      fill: '#A7A7A7',
      fontFamily: 'Trenda-Light',
      letterSpacing: '0.14rem',
      fontSize: '7px',
      textTransform: 'uppercase',
    },
  },
  chart: {
    data: {
      strokeWidth: 0,
      fill: 'url(#grad1)',
      fillOpacity: 0.2,
    },
  },
};
