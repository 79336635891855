import React, { useEffect, useState } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Typography, Button, Box } from '@material-ui/core';

interface Props {
  data: {
    url: string | null,
    file: File | null,
  }
  onSelected: (event: React.FormEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    uploadButton: {
      minWidth: 120,
      marginRight: 24,
    },
    timeRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    durationField: {
      margin: '8px 16px',
      maxWidth: 104,
    },
    timestampField: {
      margin: '8px 16px',
    },
  })
);

export const SharableQuote = (props: Props) => {
  const classes = useStyles();
  const {
    data,
    onSelected,
  } = props;
  const [sharableQuoteUploaded, setSharableQuoteUploaded] = useState(false);

  useEffect(() => {
    if (data) {
      setSharableQuoteUploaded(!!data.url);
    }
  }, [data]);

  const isSharableQuoteUploaded = data.url !== null && sharableQuoteUploaded;

  return (
    <Box  borderRadius="borderRadius" className={classes.timeRow} pt={1} pb={1} pl={2} my={1} border={1} display="flex">
      {data.url && <Box mr={2}>{data.url && <img src={data.url} height="100"></img>}</Box>}
      <div>
        <input
          id={'upload-session-image-file'}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onInput={(event: React.FormEvent<HTMLInputElement>) => onSelected(event)}
        />
        <label htmlFor={'upload-session-image-file'}>
          <Button
            component="span"
            variant="contained"
            color="secondary"
            className={classes.uploadButton}
            startIcon={<PublishIcon />}
          >
            Select
          </Button>
        </label>
      </div>
      <Typography variant="h6">{isSharableQuoteUploaded ? 'Sharable quote loaded' : 'No sharable quote'}</Typography>
    </Box>
  );
};
