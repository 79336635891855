import * as React from 'react';

import { Session } from 'types';

import { SessionListItem } from './SessionListItem';

interface SessionListProps {
  sessions: Session.Session[];
  onEdit: (sessionId: number) => void;
  index: number;
  value: number;
  hideLanguageSelect?: boolean;
}

export function SessionList(props: SessionListProps) {
  return (
    <div hidden={props.value !== props.index}>
      {props.sessions.map((session: Session.Session) => {
        return <SessionListItem key={session.id + session.title} onEdit={props.onEdit} session={session} />;
      })}
    </div>
  );
}
