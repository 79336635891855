import { withStyles } from '@material-ui/core/styles';

import { TextField } from '@material-ui/core';

const StyledTextField = withStyles({
  root: {
    '& .MuiInputLabel-root': {
      color: '#4fd1a4',
    },
  },
})(TextField);

export default StyledTextField;
