import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Typography, DialogContent, TextField, Button, Box } from '@material-ui/core';

import { fetchTestUsers, convertToTestUser } from 'state/users/thunks';
import { getTestUsersList } from 'state/users/selectors';
import { TestUser } from 'types/users';

const UsersPage = () => {
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();
  const testUsers = useSelector(getTestUsersList);

  useEffect(() => {
    dispatch(fetchTestUsers());
  }, []);

  const onConvertToTestUser = async (email: string) => {
    await dispatch(convertToTestUser(email));
    await dispatch(fetchTestUsers());
  };

  const renderTestUsers = () => {
    return testUsers.map((user: TestUser) => (
      <Typography color="primary" key={user.email} gutterBottom>
        {user.email}
      </Typography>
    ));
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <Container>
      <DialogContent>
        <TextField
          id="title"
          margin="normal"
          label="User email"
          value={email}
          onChange={onEmailChange}
          type="email"
          fullWidth
          required
          variant="outlined"
        />
        <Button onClick={() => onConvertToTestUser(email)} variant="contained" color="primary">
          Convert to test user
        </Button>
        <Box mt={3} mb={1}>
          <Typography color="initial" variant="h2">
            Test Users
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          border={1}
          borderRadius="borderRadius"
          borderColor="white"
          pt={1}
          pl={2}
        >
          {renderTestUsers()}
        </Box>
      </DialogContent>
    </Container>
  );
};

export default UsersPage;
