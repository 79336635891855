import React from 'react';

import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';

import {
  Typography,
  FormControl,
  Select,
  CircularProgress,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
} from '@material-ui/core';

import { Survey, Area } from 'types';
import { LoadingState } from 'state/types';

import { SurveyQuestionListItem, NewButton } from 'components';

const DEFAULT_QUESTION = {
  questionType: Survey.QuestionType.AREA_RELATED,
  text: '',
  areaId: 1,
  evaluateInverse: false,
} as Survey.CreateQuestionAreaRelated;

interface Props {
  isOpen: boolean;
  areas: Area.Area[];
  onSubmit: (createSurveyValues: Survey.CreateSurvey) => void;
  onCancel: () => void;
  createLoadingState: LoadingState;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    languageSelectControl: {
      minWidth: 120,
    },
    weightsSelectControl: {
      minWidth: 80,
    },
    languagePromptRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    uploadButton: {
      minWidth: 120,
      marginRight: 24,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    timeRow: {
      flexDirection: 'row',
    },
    weightsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    durationField: {
      margin: '8px 16px',
      maxWidth: 104,
    },
    timestampField: {
      margin: '8px 16px',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const StyledTextField = withStyles({
  root: {
    '& .MuiInputLabel-root': {
      color: '#4fd1a4',
    },
  },
})(TextField);

export function CreateSurveyDialog(props: Props) {
  const classes = useStyles();

  const [language, setLanguage] = React.useState(Survey.Language.DE);
  const [questionPrompt, setQuestionPrompt] = React.useState('');
  const [surveyType, setSurveyType] = React.useState(Survey.SurveyType.FLOW_CHECK);
  const [questions, setQuestions] = React.useState([DEFAULT_QUESTION] as Survey.CreateQuestion[]);

  const onLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLanguage(event.target.value as Survey.Language);
  };

  const onSurveyTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSurveyType(event.target.value as Survey.SurveyType);
  };

  const onQuestionPromptChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionPrompt(event.target.value);
  };

  const onQuestionChanged = (changedQuestion: Survey.CreateQuestion, index: number) => {
    setQuestions(
      questions.map((question: Survey.CreateQuestion, qIndex: number) =>
        qIndex === index ? changedQuestion : question
      )
    );
  };

  const renderQuestions = () => {
    return questions.map((question: Survey.CreateQuestion, index: number) => {
      return (
        <SurveyQuestionListItem
          areas={props.areas}
          onValueChanged={onQuestionChanged}
          question={question}
          index={index}
          key={index}
        />
      );
    });
  };

  const addNew = () => {
    setQuestions(questions.concat(DEFAULT_QUESTION));
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Survey</DialogTitle>
      <DialogContent>
        <Box className={classes.languagePromptRow} display="flex">
          <Box mx={2} my={1} display="flex">
            <FormControl className={classes.languageSelectControl}>
              <InputLabel id="language-input-label">Type</InputLabel>
              <Select id="language-select" value={surveyType} onChange={onSurveyTypeChange}>
                <MenuItem value={Survey.SurveyType.FLOW_CHECK}>Flow Check</MenuItem>
                <MenuItem value={Survey.SurveyType.PERSONAGE_CHECK}>Personage</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mx={2} my={1} display="flex">
            <FormControl className={classes.languageSelectControl}>
              <InputLabel id="language-input-label">Language</InputLabel>
              <Select id="language-select" value={language} onChange={onLanguageChange}>
                <MenuItem value={Survey.Language.DE}>German</MenuItem>
                <MenuItem value={Survey.Language.EN}>English</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <StyledTextField
            id="question-prompt"
            margin="normal"
            label="Question Prompt"
            value={questionPrompt}
            onChange={onQuestionPromptChanged}
            type="text"
            fullWidth
            required
            variant="outlined"
          />
        </Box>
        <Typography variant="subtitle1">Questions:</Typography>
        {renderQuestions()}
        <NewButton onClick={addNew} variant="contained" color="primary">
          New
        </NewButton>
      </DialogContent>

      <DialogActions>
        {props.createLoadingState === LoadingState.ERROR && (
          <Typography color="error">ERROR: Something went wrong</Typography>
        )}
        <Button onClick={props.onCancel} color="primary">
          Cancel
        </Button>
        <div>
          <Button
            onClick={() => {
              props.onSubmit({
                language,
                surveyType,
                questions,
                questionPrompt,
              });
            }}
            color="primary"
          >
            Ok
          </Button>
          {props.createLoadingState === LoadingState.LOADING && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}
