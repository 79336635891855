import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from '@material-ui/core';

import playStoreDe from 'assets/images/play-store-de.png';
import appStoreDe from 'assets/images/app-store-de.png';

import playStoreEn from 'assets/images/play-store-en.png';
import appStoreEn from 'assets/images/app-store-en.png';

export function StoreLinkFooter() {
  const { i18n } = useTranslation();

  const appStoreImage = !i18n.language.startsWith('en') ? appStoreDe : appStoreEn;
  const playStoreImage = !i18n.language.startsWith('en') ? playStoreDe : playStoreEn;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const logoHeight = matches ? 48 : 36;

  return (
    <Box display="flex" justifyContent="center">
      <a href="https://itunes.apple.com/app/id1443895231">
        <img alt="" src={appStoreImage} style={{ height: logoHeight }} />
      </a>
      <Box ml={2}>
        <a href="https://play.google.com/store/apps/details?id=com.flowletics.flowletics">
          <img alt="" src={playStoreImage} style={{ height: logoHeight }} />
        </a>
      </Box>
    </Box>
  );
}
