import { ThunkAction } from 'redux-thunk';

import api from 'api';

import { AppState } from '../types';

import * as actions from './actions';

export type EndpointThunkAction = ThunkAction<void, AppState, void, actions.EndpointAction>;

export function getStatistic(endpoint: string): EndpointThunkAction {
  return async (dispatch) => {
    dispatch(actions.getStatisticStart(endpoint));

    try {
      const statistic = await api.statistics.getStatistic(endpoint);

      dispatch(actions.getStatisticSuccess(endpoint, statistic));
    } catch (error) {
      dispatch(actions.getStatisticError(endpoint, error.message));
    }
  };
}

export function getStatisticsEndpoints(): EndpointThunkAction {
  return async (dispatch) => {
    dispatch(actions.resetStatistics());
    dispatch(actions.getEndpointsStart());

    try {
      const endpoints = await api.statistics.getEndpoints();
      dispatch(actions.getEndpointsSuccess(endpoints));
    } catch (error) {
      dispatch(actions.getEndpointsError(error.message));
    }
  };
}

export function getAllStatistics(): EndpointThunkAction {
  return async (dispatch) => {
    dispatch(actions.resetStatistics());
    dispatch(actions.getEndpointsStart());

    try {
      const endpoints = await api.statistics.getEndpoints();
      dispatch(actions.getEndpointsSuccess(endpoints));
      const endpointRequests = endpoints.map((endpoint) => getStatistic(endpoint));
      // Run endpointRequests as sequence
      await endpointRequests.reduce(async (previousPromise, nextEndpointRequest) => {
        await previousPromise;
        // @ts-ignore
        await nextEndpointRequest(dispatch);
      }, Promise.resolve());
    } catch (error) {
      dispatch(actions.getEndpointsError(error.message));
    }
  };
}
