import {
  UserAction,
  SET_AUTH_TOKEN,
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  REGISTER_START,
  REGISTER_SUCCESS,
  USER_LOGOUT,
  FETCH_USER_PROPERTIES_SUCCESS,
  GET_USER_ACCOUNT_INFORMATION_SUCCESS,
  VERIFY_USER_EMAIL_START,
  VERIFY_USER_EMAIL_SUCCESS,
  VERIFY_USER_EMAIL_ERROR,
  GET_SUBSCRIPTIONS_START,
  GET_SUBSCRIPTIONS_SUCCESS,
} from './actions';

import { UserState, LoadingState } from '../types';

const initialState: UserState = {
  lastTokenRefreshDate: null,
  userProperties: null,
  token: null,
  isAuthenticated: false,
  userAccountInformation: null,
  emailVerified: LoadingState.IDLE,
  loginLoading: LoadingState.IDLE,
  subscriptions: [],
  subscriptionsLoading: LoadingState.IDLE,
};

export const userReducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return { ...state, token: action.token };

    case USER_LOGIN_START:
      return { ...state, loginLoading: LoadingState.LOADING };

    case USER_LOGIN_SUCCESS:
      return { ...state, loginLoading: LoadingState.SUCCESS, isAuthenticated: true };

    case REGISTER_START:
      return { ...state, loginLoading: LoadingState.LOADING };

    case REGISTER_SUCCESS:
      return { ...state, loginLoading: LoadingState.SUCCESS, token: action.token, isAuthenticated: true };

    case USER_LOGOUT:
      return { ...state, token: null, isAuthenticated: false };

    case FETCH_USER_PROPERTIES_SUCCESS:
      return { ...state, userProperties: action.userProperties };

    case GET_USER_ACCOUNT_INFORMATION_SUCCESS:
      return { ...state, userAccountInformation: action.userAccountInformation };

    case VERIFY_USER_EMAIL_START:
      return { ...state, emailVerified: LoadingState.LOADING };

    case VERIFY_USER_EMAIL_SUCCESS:
      return { ...state, emailVerified: LoadingState.SUCCESS };

    case VERIFY_USER_EMAIL_ERROR:
      return { ...state, emailVerified: LoadingState.ERROR };

    case GET_SUBSCRIPTIONS_START:
      return { ...state, subscriptionsLoading: LoadingState.LOADING };

    case GET_SUBSCRIPTIONS_SUCCESS:
      return { ...state, subscriptionsLoading: LoadingState.SUCCESS, subscriptions: action.subscriptions };

    default:
      return state;
  }
};
