import * as React from 'react';

import { styled } from '@material-ui/core/styles';
import { Paper, Box, Grid, Typography } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const AreaPaper = styled(Paper)({
  padding: 16,
});

const AreasPage = () => {
  return (
    <Box style={{ margin: 16 }}>
      <Grid spacing={2} container>
        <Grid item xs={6}>
          <AreaPaper>
            <Typography variant="h4">Fokus</Typography>
          </AreaPaper>
        </Grid>
        <Grid item xs={6}>
          <AreaPaper>
            <Typography variant="h4">Leichtigkeit</Typography>
          </AreaPaper>
        </Grid>
        <Grid item xs={6}>
          <AreaPaper>
            <Typography variant="h4">Optimismus</Typography>
          </AreaPaper>
        </Grid>
        <Grid item xs={6}>
          <AreaPaper>
            <Typography variant="h4">Wachstum</Typography>
          </AreaPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AreasPage;
