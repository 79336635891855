import * as React from 'react';

import { connect } from 'react-redux';

import { Container } from '@material-ui/core';

import { thunks } from 'state';

import { Survey, Area } from 'types';
import { AppState, AppDispatch, LoadingState } from 'state/types';

import { NewButton, CreateSurveyDialog } from 'components';

interface PropsFromState {
  areas: Area.Area[];
}

interface DispatchProps {
  getAllAreas: () => void;
  createSurvey: (createSurveyValues: Survey.CreateSurvey) => Promise<LoadingState>;
}

type Props = PropsFromState & DispatchProps;

interface State {
  createDialogOpen: boolean;
  createLoadingState: LoadingState;
}

class SurveysPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      createDialogOpen: false,
      createLoadingState: LoadingState.IDLE,
    };
  }

  componentDidMount() {
    this.props.getAllAreas();
  }

  showNew = () => {
    this.setState({
      createDialogOpen: true,
    });
  };

  submitCreate = async (createSurveyValues: Survey.CreateSurvey) => {
    this.setState({
      createLoadingState: LoadingState.LOADING,
    });

    const result = await this.props.createSurvey(createSurveyValues);

    if (result === LoadingState.SUCCESS) {
      this.setState({
        createLoadingState: LoadingState.SUCCESS,
        createDialogOpen: false,
      });
    } else {
      this.setState({
        createLoadingState: LoadingState.ERROR,
      });
    }
  };

  cancelCreate = () => {
    this.setState({
      createDialogOpen: false,
      createLoadingState: LoadingState.IDLE,
    });
  };

  render() {
    if (!this.props.areas) {
      return <div />;
    }

    return (
      <Container>
        <NewButton onClick={this.showNew} variant="contained" color="primary">
          New
        </NewButton>
        {this.state.createDialogOpen && (
          <CreateSurveyDialog
            isOpen={this.state.createDialogOpen}
            onSubmit={this.submitCreate}
            onCancel={this.cancelCreate}
            areas={this.props.areas}
            createLoadingState={this.state.createLoadingState}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    areas: state.area.areas,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    getAllAreas: () => dispatch(thunks.area.getAllAreas()),
    createSurvey: (createSurveyValues: Survey.CreateSurvey) => dispatch(thunks.survey.createSurvey(createSurveyValues)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveysPage);
