import { AxiosResponse } from 'axios';

import { Promotion, Subscription } from 'types';

import api from './config';

interface PromoProductResponse {
  name: string;
  renewal_type: Subscription.Interval;
}

interface PromoResponse {
  benefit: string;
  products?: PromoProductResponse[];
}

function promoResponseToPromotion(productResponse: PromoResponse): Promotion.Promotion {
  return {
    benefit: productResponse.benefit,
    products:
      productResponse.products &&
      productResponse.products.map((product) => {
        return {
          name: product.name,
          renewalType: product.renewal_type,
          discountEndDate: '',
        };
      }),
  };
}

export const getCodeDetails = (code: string): Promise<Promotion.Promotion | null> =>
  api
    .get(`/promotion/code/${code}/`)
    .then((res: AxiosResponse) => {
      return promoResponseToPromotion(res.data);
    })
    .catch(() => {
      return null;
    });

export const useCode = (code: string): Promise<boolean> =>
  api
    .patch(`/promotion/code/${code}/`)
    .then((res: AxiosResponse) => {
      return res.status === 200;
    })
    .catch(() => false);
