export interface Properties {
  isTrainer: boolean;
  isAdmin: boolean;
  hasTrial: boolean;
  hasPaidSubscription: boolean;
  hasLifetimeSubscription: boolean;
  isConfirmed: boolean;
  isTest: boolean;
}

export interface Credentials {
  login: string;
  password: string;
}

export interface AuthCredentials {
  accessToken: string;
  provider: SocialProvider;
  preferredLanguage: string;
}

export interface AccountInformation {
  gender: Gender;
  username: string;
  imageUrl: string | null;
  firstName: string;
  lastName: string;
  signedForNewsletter: boolean;
  birthday: BirthDay | null;
  dateJoined: string;
}

export enum Gender {
  male = 'm',
  female = 'f',
  diverse = 'd',
  noAnswer = 'na',
}

export enum SocialProvider {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export interface BirthDay {
  value: string;
  age: number;
}

export interface RegistrationData {
  email: string;
  password: string;
  preferredLanguage: string;
  gender: string;
  signedForNewsletter: boolean;
  marketingCompany: string;
}

export interface AuthenticationData {
  accessToken: string;
  provider: SocialProvider;
  preferredLanguage: string;
}
