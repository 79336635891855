export const de = {
  translation: {
    menu: {
      account: 'Account',
      logout: 'Logout',
      register: 'Registrieren',
      login: 'Login',
    },
    landing: {
      title: 'Finde deinen Flow mit der Flow Lab App',
      bullet11: 'Erhalte Zugriff auf ',
      bullet12: 'alle Flow-Sessions inkl. Offline-Funktion',
      bullet21: 'Trainiere regelmäßig mit ',
      bullet22: 'deinem individuellen Wochenplan',
      bullet31: 'Verfolge deinen Fortschritt ',
      bullet32: 'auf dem Weg zu mehr Flow',
      register: 'Registrieren',
      login: 'Log in',
    },
    verifyEmail: {
      success: 'Dein Account ist nun aktiviert!',
      error: 'Das aktivieren deines Accounts ist Fehlgeschlagen!',
      tryAgain: 'Versuche es bitte noch einmal.',
    },
    login: {
      title: 'Login',
      email: 'Email',
      password: 'Passwort',
      button: 'Login',
      withFacebook: 'Login mit Facebook',
      withGoogle: 'Login mit Google',
      wrongCredentials: 'Falsche Email oder Passwort',
      socialError: 'Leider ist etwas schief gegangen',
    },
    register: {
      title: 'Registrierung',
      email: 'Email',
      password: 'Passwort',
      button: 'Register',
      withFacebook: 'Mit Facebook registrieren',
      withGoogle: 'Mit Google registrieren',
      socialError: 'Leider ist etwas schief gegangen',
      gender: {
        male: 'männlich',
        female: 'weiblich',
        diverse: 'divers',
        noAnswer: 'keine Angabe',
      },
    },
    resetPassword: {
      title: 'Passwort ändern',
      passwordInputLabel: 'Neues Passwort',
      repeatPasswordInputLabel: 'Neues Passwort wiederholen',
      notMatchingError: 'Passwörter stimmen nicht überein',
      backendError: 'Leider ist etwas schief gegangen',
      submitButton: 'Passwort ändern',
      success: 'Dein Passwort wurde erfolgreich geändert!',
    },
    account: {
      yourSubscription: 'Dein Abo',
      willRenewOn: 'Wird verlängert am: {{date}}',
      willExpireOn: 'Wird ablaufen am: {{date}}',
      enterPromoCode: 'Du hast einen Promo-Code?',
      promoCode: 'Code eingeben',
      yourBenefit: 'Du erhälst:',
      resendCode: 'Erneut senden',
      submitPromoCode: 'Einlösen',
      confirmEmailDialogTitle: 'Email bestätigen',
      confirmEmailDialogMessage: 'Bestätige bitte deine Email Addresse, damit du den Code einlösen kannst.',
      congratulationsDialogTitle: 'Glückwunsch',
      congratulationsDialogMessageDiscount: 'Öffne die Flow Lab App, um dein ermäßigtes Abo freizuschalten.',
      congratulationsDialogMessageSubscription: 'Du hast Flow Lab Pro freigeschaltet.',
      welcomeToFlowLab: 'Willkommen bei Flow Lab – deinem \n Coach für mentale Fitness!',
      downloadAndStart: 'Starte dein Training mit unserer App.',
    },
    footer: {
      imprint: 'Impressum',
      privacyPolicy: 'Datenschutzerklärung',
      toc: 'AGB',
    },
  },
};
