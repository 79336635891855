import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Box, Typography } from '@material-ui/core';
import { styled, createStyles, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import { StoreLinkFooter, PageGrid, PageBackground } from 'components';
import { getUserProperties, getIsAuthenticated } from 'state/user/selectors';

const useStyles = makeStyles(() =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column' as 'column',
    },
    textContainer: {
      maxWidth: 500,
      width: '100%',
    },
    storeContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    storeImageLink: {
      height: 50,
    },
  })
);

const ActionButton = styled(Button)({
  marginLeft: 48,
  marginRight: 48,
  fontWeight: 'bold',
  minWidth: 180,
  marginTop: 16,
  alignSelf: 'stretch',
});

const Check = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ minWidth: 32, width: 32, backgroundColor: '#4fd1a4', height: 32, borderRadius: 16 }}
    >
      <CheckIcon style={{ fontSize: 24 }} />
    </Box>
  );
};

export const CatchAllPage = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const userProperties = useSelector(getUserProperties);
  const history = useHistory();

  const classes = useStyles();

  const { t } = useTranslation();

  const toRegister = () => {
    history.push('/register');
  };

  const toLogin = () => {
    history.push('/login');
  };

  if (isAuthenticated) {
    if (userProperties && userProperties.isAdmin) {
      return <Redirect to="/admin/sessions" />;
    } else {
      return <Redirect to="/account" />;
    }
  } else {
    return (
      <PageBackground>
        <Box flex={1} style={{ width: '100%' }}>
          <PageGrid>
            <Box className={classes.contentContainer}>
              <Box style={{ width: '100%' }} flex={1} mt={10}>
                <Typography align="center" variant="h1">
                  {t('landing.title')}
                </Typography>
              </Box>
              <Box display="flex" flex={1} mt={6} flexDirection="row">
                <Check />
                <Box ml={2}>
                  <Typography display="inline" variant="h2">
                    {t('landing.bullet11')}
                  </Typography>
                  <Typography display="inline" variant="h1">
                    {t('landing.bullet12')}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flex={1} mt={2} flexDirection="row">
                <Check />
                <Box ml={2}>
                  <Typography display="inline" variant="h2">
                    {t('landing.bullet21')}
                  </Typography>
                  <Typography display="inline" variant="h1">
                    {t('landing.bullet22')}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flex={1} mt={2} flexDirection="row">
                <Check />
                <Box ml={2}>
                  <Typography display="inline" variant="h1">
                    {t('landing.bullet31')}
                  </Typography>
                  <Typography display="inline" variant="h2">
                    {t('landing.bullet32')}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignSelf="center"
                style={{ maxWidth: 360 }}
                alignItems="center"
                flexDirection="column"
                mt={4}
              >
                <ActionButton type="submit" variant="contained" color="primary" size="large" onClick={toRegister}>
                  {t('landing.register')}
                </ActionButton>
                <ActionButton type="submit" variant="contained" color="primary" size="large" onClick={toLogin}>
                  {t('landing.login')}
                </ActionButton>
              </Box>
            </Box>
          </PageGrid>
        </Box>
        <Box my={4} style={{ width: '100%' }}>
          <PageGrid>
            <StoreLinkFooter />
          </PageGrid>
        </Box>
      </PageBackground>
    );
  }
};
