import * as AreaApi from './area';
import * as NotificationApi from './notification';
import * as PromotionApi from './promotion';
import * as SessionApi from './session';
import * as StatisticsApi from './statistics';
import * as SubscriptionApi from './subscription';
import * as SurveyApi from './survey';
import * as UserApi from './user';
import * as UsersApi from './users';

export default {
  area: AreaApi,
  notification: NotificationApi,
  promotion: PromotionApi,
  session: SessionApi,
  statistics: StatisticsApi,
  subscription: SubscriptionApi,
  survey: SurveyApi,
  user: UserApi,
  users: UsersApi,
};
