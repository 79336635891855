import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AdminRouter from './AdminRouter';
import UserRouter from './UserRouter';

const MainRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/admin" component={AdminRouter} />
      <Route path={['/user', '/register', '/registration', '/account', '/login', '/']} component={UserRouter} />
    </Switch>
  </BrowserRouter>
);

export default MainRouter;
