import { ThunkAction } from 'redux-thunk';

import api from 'api';

import { Survey } from 'types';

import { AppState, LoadingState } from '../types';

import * as actions from './actions';

export type SurveyThunkAction = ThunkAction<Promise<LoadingState>, AppState, void, actions.SurveyAction>;

export function createSurvey(createSurveyValues: Survey.CreateSurvey): SurveyThunkAction {
  return async (dispatch) => {
    dispatch(actions.createSurveyStart());

    try {
      await api.survey.createSurvey(createSurveyValues);

      dispatch(actions.createSurveySuccess());

      return LoadingState.SUCCESS;
    } catch (error) {
      dispatch(actions.createSurveyError(error.message));

      return LoadingState.ERROR;
    }
  };
}
