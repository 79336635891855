import * as React from 'react';

import { connect } from 'react-redux';

import { Box, FormControl, InputLabel, Select, MenuItem, Container, AppBar, Tabs, Tab } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { thunks } from 'state';

import { Session, Area, Language } from 'types';
import { AppState, AppDispatch } from 'state/types';

import { NewButton, SessionList, EditCreateSessionDialog, DialogMode } from 'components';

function filterSessions(areaId: number, sessions: Session.Session[], language: Language) {
  return sessions.filter((session: Session.Session) => {
    return session.mainFlowArea === areaId && (session.flowAreaWeights.length === 0 || session.language === language);
  });
}

interface PropsFromState {
  sessions: Session.Session[];
  unweightedSessions: Session.Session[];
  areas: Area.Area[];
}

interface DispatchProps {
  getAllAreas: () => void;
  getAllSessions: () => void;
  editSession: (sessionId: number, session: Session.Props) => void;
  createSession: (sessionProps: Session.Props) => void;
}

const NEW_SESSION_PROPS = {
  title: '',
  shortDescription: '',
  fullDescription: '',
  duration: 0,
  fileId: null,
  isIntro: false,
  isFirst: false,
  paidOnly: true,
  introTimestamp: '00:00:00',
  outroTimestamp: '00:00:00',
  language: Language.DE,
  flowAreaWeights: [],
  variations: [],
  id: -1,
  mainFlowArea: null,
  sharableQuote: {
    url: null,
    file: null,
  },
};

type Props = PropsFromState & DispatchProps & WithStyles;

const styles = {
  languageSelectControl: {
    minWidth: 120,
  },
};

interface State {
  tabIndex: number;
  createEditOpen: boolean;
  createEditMode: DialogMode;
  editCreateSessionProps: Session.Session;
  sessionLanguage: Language;
  languageSelectEnabled: boolean;
}

class SessionsPage extends React.PureComponent<Props, State> {
  sessionToEdit: number | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      tabIndex: 0,
      createEditOpen: false,
      createEditMode: DialogMode.EDIT,
      editCreateSessionProps: NEW_SESSION_PROPS,
      sessionLanguage: Language.DE,
      languageSelectEnabled: true,
    };
  }

  componentDidMount() {
    this.props.getAllAreas();
    this.props.getAllSessions();
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue,
      languageSelectEnabled: newValue !== 5,
    });
  };

  cancelEditCreate = () => {
    this.sessionToEdit = null;

    this.setState({
      createEditOpen: false,
    });
  };

  submitEditCreate = (sessionProps: Session.Props) => {
    if (this.state.createEditMode === DialogMode.EDIT) {
      if (this.sessionToEdit !== null) {
        this.props.editSession(this.sessionToEdit, sessionProps);
      }
    } else if (this.state.createEditMode === DialogMode.CREATE) {
      this.props.createSession(sessionProps);
    }

    this.setState({
      createEditOpen: false,
    });
  };

  showEdit = (sessionId: number) => {
    const session = this.props.sessions.find((session: Session.Session) => session.id === sessionId);
    this.sessionToEdit = sessionId;

    if (session) {
      this.setState({
        createEditOpen: true,
        createEditMode: DialogMode.EDIT,
        editCreateSessionProps: session,
      });
    }
  };

  showNew = () => {
    this.setState({
      createEditOpen: true,
      createEditMode: DialogMode.CREATE,
      editCreateSessionProps: NEW_SESSION_PROPS,
    });
  };

  renderAreaTabBar = () => {
    return this.props.areas.map((area: Area.Area, index: number) => (
      <Tab key={area.name} value={index} label={area.name} />
    ));
  };

  renderAreaTabs = () => {
    return this.props.areas.map((area: Area.Area, index: number) => (
      <SessionList
        key={this.props.areas[index].name}
        value={index}
        index={this.state.tabIndex}
        sessions={filterSessions(area.id, this.props.sessions, this.state.sessionLanguage)}
        onEdit={this.showEdit}
      />
    ));
  };

  renderUnweightedSessions = () => {
    return (
      <SessionList
        hideLanguageSelect
        value={5}
        index={this.state.tabIndex}
        sessions={this.props.unweightedSessions}
        onEdit={this.showEdit}
      />
    );
  };

  renderTabs = () => {
    return (
      <AppBar position="static">
        <Tabs value={this.state.tabIndex} onChange={this.handleTabChange} aria-label="wrapped label tabs example">
          {this.renderAreaTabBar()}
          <Tab value={5} label="Unweighted" />
        </Tabs>
      </AppBar>
    );
  };

  findSession = (sessionId: number) => {
    return this.props.sessions.find((session: Session.Session) => session.id === sessionId);
  };

  onLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      sessionLanguage: event.target.value as Language,
    });
  };

  render() {
    if (!this.props.areas || !this.props.sessions) {
      return <div />;
    }

    return (
      <Container>
        <Box ml={3} my={4} display="flex">
          <FormControl className={this.props.classes.languageSelectControl}>
            <InputLabel id="language-input-label">Language</InputLabel>
            <Select
              id="language-select"
              disabled={!this.state.languageSelectEnabled}
              value={this.state.languageSelectEnabled ? this.state.sessionLanguage : 1}
              onChange={this.onLanguageChange}
            >
              <MenuItem value={Language.DE}>German</MenuItem>
              <MenuItem value={Language.EN}>English</MenuItem>
              {!this.state.languageSelectEnabled && <MenuItem value={1}>All</MenuItem>}
            </Select>
          </FormControl>
        </Box>
        {this.renderTabs()}
        {this.renderAreaTabs()}
        {this.renderUnweightedSessions()}
        <NewButton onClick={this.showNew} variant="contained" color="primary">
          New
        </NewButton>
        <EditCreateSessionDialog
          isOpen={this.state.createEditOpen}
          mode={this.state.createEditMode}
          onSubmit={this.submitEditCreate}
          onCancel={this.cancelEditCreate}
          sessionProps={this.state.editCreateSessionProps}
        />
      </Container>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    areas: state.area.areas,
    sessions: state.session.sessions,
    unweightedSessions: state.session.unweightedSessions,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    getAllAreas: () => dispatch(thunks.area.getAllAreas()),
    getAllSessions: () => dispatch(thunks.session.getAllSessions()),
    editSession: (sessionId: number, sessionProps: Session.Props) =>
      dispatch(thunks.session.editSession(sessionId, sessionProps)),
    createSession: (sessionProps: Session.Props) => dispatch(thunks.session.createSession(sessionProps)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SessionsPage));
