import { Statistics } from 'types';

export const GET_ENDPOINTS_START = 'GET_ENDPOINTS_START';
export const GET_ENDPOINTS_SUCCESS = 'GET_ENDPOINTS_SUCCESS';
export const GET_ENDPOINTS_ERROR = 'GET_ENDPOINTS_ERROR';

export interface GetEndpointsStart {
  type: typeof GET_ENDPOINTS_START;
}

export const getEndpointsStart = (): GetEndpointsStart => ({
  type: GET_ENDPOINTS_START,
});

export interface GetEndpointsSuccess {
  type: typeof GET_ENDPOINTS_SUCCESS;
  endpoints: string[];
}

export const getEndpointsSuccess = (endpoints: string[]): GetEndpointsSuccess => ({
  type: GET_ENDPOINTS_SUCCESS,
  endpoints,
});

export interface GetEndpointsError {
  type: typeof GET_ENDPOINTS_ERROR;
  message: string;
}

export const getEndpointsError = (message: string): GetEndpointsError => ({
  type: GET_ENDPOINTS_ERROR,
  message,
});

export const GET_STATISTIC_START = 'GET_STATISTIC_START';
export const GET_STATISTIC_SUCCESS = 'GET_STATISTIC_SUCCESS';
export const GET_STATISTIC_ERROR = 'GET_STATISTIC_ERROR';

export interface GetStatisticStart {
  type: typeof GET_STATISTIC_START;
  endpoint: string;
}

export const getStatisticStart = (endpoint: string): GetStatisticStart => ({
  type: GET_STATISTIC_START,
  endpoint,
});

export interface GetStatisticSuccess {
  type: typeof GET_STATISTIC_SUCCESS;
  endpoint: string;
  statistic: Statistics.Statistic;
}

export const getStatisticSuccess = (endpoint: string, statistic: Statistics.Statistic): GetStatisticSuccess => ({
  type: GET_STATISTIC_SUCCESS,
  endpoint,
  statistic,
});

export interface GetStatisticError {
  type: typeof GET_STATISTIC_ERROR;
  endpoint: string;
  message: string;
}

export const getStatisticError = (endpoint: string, message: string): GetStatisticError => ({
  type: GET_STATISTIC_ERROR,
  endpoint,
  message,
});

export const RESET_STATISTICS = 'RESET_STATISTICS';

export interface ResetStatistics {
  type: typeof RESET_STATISTICS;
}

export const resetStatistics = (): ResetStatistics => ({
  type: RESET_STATISTICS,
});

export type EndpointAction =
  | GetEndpointsStart
  | GetEndpointsSuccess
  | GetEndpointsError
  | GetStatisticStart
  | GetStatisticSuccess
  | GetStatisticError
  | ResetStatistics;
