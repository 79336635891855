export const CREATE_SURVEY_START = 'CREATE_SURVEY_START';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_ERROR = 'CREATE_SURVEY_ERROR';

export interface CreateSurveyStart {
  type: typeof CREATE_SURVEY_START;
}

export const createSurveyStart = (): CreateSurveyStart => ({
  type: CREATE_SURVEY_START,
});

export interface CreateSurveySuccess {
  type: typeof CREATE_SURVEY_SUCCESS;
}

export const createSurveySuccess = (): CreateSurveySuccess => ({
  type: CREATE_SURVEY_SUCCESS,
});

export interface CreateSurveyError {
  type: typeof CREATE_SURVEY_ERROR;
  message: string;
}

export const createSurveyError = (message: string): CreateSurveyError => ({
  type: CREATE_SURVEY_ERROR,
  message,
});

export type SurveyAction = CreateSurveyStart | CreateSurveySuccess | CreateSurveyError;
