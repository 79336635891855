import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Button, CircularProgress, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { LoadingState } from 'state/types';
import { facebookAppId } from 'api/config';
import logo from 'assets/images/facebook.png';

interface Props {
  onPress: Function;
  login?: boolean;
  loadingState: LoadingState;
}

const FacebookLoginButton = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const styles = {
    button: {
      marginTop: theme.spacing(2),
      alignSelf: 'stretch',
      backgroundColor: '#3d5b97',
      color: 'white',
    },
    loadingIndicator: {
      color: theme.palette.background.default,
    },
    logo: {
      width: 25,
      height: 25,
    },
  };

  const { onPress, loadingState, login } = props;
  return (
    <FacebookLogin
      appId={facebookAppId}
      fields="name,email,picture"
      callback={onPress}
      render={(renderProps: { onClick: () => void; disabled?: boolean }) => (
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={styles.button}
          disabled={loadingState === LoadingState.LOADING}
          onClick={renderProps.onClick}
          startIcon={<img alt="" src={logo} style={styles.logo} />}
        >
          {loadingState === LoadingState.LOADING ? (
            <CircularProgress size={24} style={styles.loadingIndicator} />
          ) : (
            t(login ? 'login.withFacebook' : 'register.withFacebook')
          )}
        </Button>
      )}
    />
  );
};

export { FacebookLoginButton };
