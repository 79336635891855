import * as React from 'react';

import { Grid } from '@material-ui/core';

interface Props {
  children: React.ReactNode;
}

export function PageGrid(props: Props) {
  return (
    <Grid container>
      <Grid item xs={1} md={4} />
      <Grid item xs={10} md={4}>
        {props.children}
      </Grid>
      <Grid item xs={1} md={4} />
    </Grid>
  );
}
