import React, { useEffect, useState } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from './TextField';
import { Variation } from 'types/session';

import { Typography, Button, Box } from '@material-ui/core';
import { getAudioFileInfo } from 'api/audioFile';

interface Props {
  index: number;
  variation: Variation;
  onDurationChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onIntroTimestampChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onOutroTimestampChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onAudioFileSelected: (event: React.FormEvent<HTMLInputElement>, index: number) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    uploadButton: {
      minWidth: 120,
      marginRight: 24,
    },
    timeRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    durationField: {
      margin: '8px 16px',
      maxWidth: 104,
    },
    timestampField: {
      margin: '8px 16px',
    },
  })
);

function timestampToInput(timestamp: string | null) {
  if (timestamp === null) {
    return '00:00';
  } else {
    return timestamp.substring(3);
  }
}

export const VariationRow = (props: Props) => {
  const classes = useStyles();
  const [fileUploaded, setFileUploaded] = useState(false);
  const {
    index,
    variation,
    onDurationChange,
    onIntroTimestampChange,
    onOutroTimestampChange,
    onAudioFileSelected,
  } = props;

  useEffect(() => {
    const updateAudioFileUploadState = async () => {
      if (variation.fileId) {
        const fileInfo = await getAudioFileInfo(variation.fileId);
        setFileUploaded(!!fileInfo);
      }
    };
    updateAudioFileUploadState();
  }, [variation]);

  const isAudioFileUploaded = variation.fileId !== null && fileUploaded;

  return (
    <Box my={1} className={classes.timeRow} display="flex">
      <TextField
        id="duration"
        margin="normal"
        label="duration"
        helperText="(minutes)"
        className={classes.durationField}
        value={variation.duration.toString()}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onDurationChange(event, index)}
        type="number"
        required
        variant="outlined"
      />
      <TextField
        id="intro"
        margin="normal"
        label="intro"
        helperText="mm:ss"
        className={classes.timestampField}
        value={timestampToInput(variation.introTimestamp)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onIntroTimestampChange(event, index)}
        type="time"
        required
        variant="outlined"
      />
      <TextField
        id="outro"
        margin="normal"
        label="outro"
        helperText="mm:ss"
        className={classes.timestampField}
        value={timestampToInput(variation.outroTimestamp)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onOutroTimestampChange(event, index)}
        type="time"
        required
        variant="outlined"
      />
      <div>
        <input
          id={`upload-audio-file-${index}`}
          type="file"
          style={{ display: 'none' }}
          accept="audio/*"
          onInput={(event: React.FormEvent<HTMLInputElement>) => onAudioFileSelected(event, index)}
        />
        <label htmlFor={`upload-audio-file-${index}`}>
          <Button
            component="span"
            variant="contained"
            color="secondary"
            className={classes.uploadButton}
            startIcon={<PublishIcon />}
          >
            Upload
          </Button>
        </label>
      </div>
      <Typography variant="h6">{isAudioFileUploaded ? 'Audio file exists' : 'No audio file'}</Typography>
    </Box>
  );
};
