import * as React from 'react';

import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Location } from 'history';

import { Box, Typography, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { thunks } from 'state';
import { LoadingState, AppState, AppDispatch } from 'state/types';

const ContentContainer = withStyles({
  root: {
    alignItems: 'center',
    flexDirection: 'column',
  },
})(Box);

interface Props extends WithTranslation {
  location: Location;
  emailVerified: LoadingState;
  verifyEmail: (path: string) => void;
}

class VerifyEmailPage extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.verifyEmail(this.props.location.pathname);
  }

  renderSuccess = () => (
    <Typography color="textPrimary" variant="h4">
      {this.props.t('verifyEmail.success')}
    </Typography>
  );

  renderError = () => (
    <>
      <Typography color="textPrimary" variant="h5">
        {this.props.t('verifyEmail.error')}
      </Typography>
      <Typography color="textPrimary" variant="body1">
        {this.props.t('verifyEmail.tryAgain')}
      </Typography>
    </>
  );

  renderState = () => {
    switch (this.props.emailVerified) {
      case LoadingState.LOADING:
      case LoadingState.IDLE:
        return <CircularProgress />;
      case LoadingState.SUCCESS:
        return this.renderSuccess();
      case LoadingState.ERROR:
        return this.renderError();
    }
  };

  render() {
    return (
      <ContentContainer mt={6} display="flex">
        {this.renderState()}
      </ContentContainer>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    emailVerified: state.user.emailVerified,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    verifyEmail: (path: string) => dispatch(thunks.user.verifyEmail(path)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VerifyEmailPage));
