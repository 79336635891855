export enum Type {
  GRAPH = 'GRAPH',
  TABLE = 'TABLE',
  KEY_VALUE = 'KEY_VALUE',
}

interface GraphElement {
  x: string;
  y: string;
}

export type GraphData = GraphElement[];

export interface GraphStatistic {
  type: Type.GRAPH;
  data: GraphData;
  title: string;
}

type TableValue = number | string;

type TableRow = TableValue[];

export type TableData = TableRow[];

export interface TableStatistic {
  type: Type.TABLE;
  data: TableData;
  title: string;
}

export interface KeyValueElement {
  [key: string]: string;
}

export interface KeyValueStatistic {
  type: Type.KEY_VALUE;
  data: KeyValueElement[];
  title: string;
}

export type Statistic = GraphStatistic | TableStatistic | KeyValueStatistic;
