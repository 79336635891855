import { Session } from 'types';

export const GET_SESSIONS_START = 'GET_SESSIONS_START';
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_ERROR = 'GET_SESSIONS_ERROR';

export interface GetSessionsStart {
  type: typeof GET_SESSIONS_START;
}

export const getSessionsStart = (): GetSessionsStart => ({
  type: GET_SESSIONS_START,
});

export interface GetSessionsSuccess {
  type: typeof GET_SESSIONS_SUCCESS;
  sessions: Session.Session[];
}

export const getSessionsSuccess = (sessions: Session.Session[]): GetSessionsSuccess => ({
  type: GET_SESSIONS_SUCCESS,
  sessions,
});

export interface GetSessionsError {
  type: typeof GET_SESSIONS_ERROR;
  message: string;
}

export const getSessionsError = (message: string): GetSessionsError => ({
  type: GET_SESSIONS_ERROR,
  message,
});

export const GET_UNWEIGHTED_SESSIONS_START = 'GET_UNWEIGHTED_SESSIONS_START';
export const GET_UNWEIGHTED_SESSIONS_SUCCESS = 'GET_UNWEIGHTED_SESSIONS_SUCCESS';
export const GET_UNWEIGHTED_SESSIONS_ERROR = 'GET_UNWEIGHTED_SESSIONS_ERROR';

export interface GetUnweightedSessionsStart {
  type: typeof GET_UNWEIGHTED_SESSIONS_START;
}

export const getUnweightedSessionsStart = (): GetUnweightedSessionsStart => ({
  type: GET_UNWEIGHTED_SESSIONS_START,
});

export interface GetUnweightedSessionsSuccess {
  type: typeof GET_UNWEIGHTED_SESSIONS_SUCCESS;
  unweightedSessions: Session.Session[];
}

export const getUnweightedSessionsSuccess = (unweightedSessions: Session.Session[]): GetUnweightedSessionsSuccess => ({
  type: GET_UNWEIGHTED_SESSIONS_SUCCESS,
  unweightedSessions,
});

export interface GetUnweightedSessionsError {
  type: typeof GET_UNWEIGHTED_SESSIONS_ERROR;
  message: string;
}

export const getUnweightedSessionsError = (message: string): GetUnweightedSessionsError => ({
  type: GET_UNWEIGHTED_SESSIONS_ERROR,
  message,
});

export const EDIT_SESSION_START = 'EDIT_SESSION_START';
export const EDIT_SESSION_SUCCESS = 'EDIT_SESSION_SUCCESS';
export const EDIT_SESSION_ERROR = 'EDIT_SESSION_ERROR';

export interface EditSessionStart {
  type: typeof EDIT_SESSION_START;
}

export const editSessionStart = (): EditSessionStart => ({
  type: EDIT_SESSION_START,
});

export interface EditSessionSuccess {
  type: typeof EDIT_SESSION_SUCCESS;
  sessionId: number;
  sessionProps: Session.Props;
}

export const editSessionSuccess = (sessionId: number, sessionProps: Session.Props): EditSessionSuccess => ({
  type: EDIT_SESSION_SUCCESS,
  sessionId,
  sessionProps,
});

export interface EditSessionError {
  type: typeof EDIT_SESSION_ERROR;
  message: string;
}

export const editSessionError = (message: string): EditSessionError => ({
  type: EDIT_SESSION_ERROR,
  message,
});

export const CREATE_SESSION_START = 'CREATE_SESSION_START';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_ERROR = 'CREATE_SESSION_ERROR';

export interface CreateSessionStart {
  type: typeof CREATE_SESSION_START;
}

export const createSessionStart = (): CreateSessionStart => ({
  type: CREATE_SESSION_START,
});

export interface CreateSessionSuccess {
  type: typeof CREATE_SESSION_SUCCESS;
  sessions: Session.Session[];
}

export const createSessionSuccess = (sessions: Session.Session[]): CreateSessionSuccess => ({
  type: CREATE_SESSION_SUCCESS,
  sessions,
});

export interface CreateSessionError {
  type: typeof CREATE_SESSION_ERROR;
  message: string;
}

export const createSessionError = (message: string): CreateSessionError => ({
  type: CREATE_SESSION_ERROR,
  message,
});

export const UPLOAD_AUDIO_START = 'UPLOAD_AUDIO_START';
export const UPLOAD_AUDIO_SUCCESS = 'UPLOAD_AUDIO_SUCCESS';
export const UPLOAD_AUDIO_ERROR = 'UPLOAD_AUDIO_ERROR';

export interface UploadAudioStart {
  type: typeof UPLOAD_AUDIO_START;
}

export const uploadAudioStart = (): UploadAudioStart => ({
  type: UPLOAD_AUDIO_START,
});

export interface UploadAudioSuccess {
  type: typeof UPLOAD_AUDIO_SUCCESS;
  sessions: Session.Session[];
}

export const uploadAudioSuccess = (sessions: Session.Session[]): UploadAudioSuccess => ({
  type: UPLOAD_AUDIO_SUCCESS,
  sessions,
});

export interface UploadAudioError {
  type: typeof UPLOAD_AUDIO_ERROR;
  message: string;
}

export const uploadAudioError = (message: string): UploadAudioError => ({
  type: UPLOAD_AUDIO_ERROR,
  message,
});

export type SessionAction =
  | GetSessionsStart
  | GetSessionsSuccess
  | GetSessionsError
  | GetUnweightedSessionsStart
  | GetUnweightedSessionsSuccess
  | GetUnweightedSessionsError
  | EditSessionStart
  | EditSessionSuccess
  | EditSessionError
  | CreateSessionStart
  | CreateSessionSuccess
  | CreateSessionError
  | UploadAudioStart
  | UploadAudioSuccess
  | UploadAudioError;
