import * as React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { FormControl, Select, InputLabel, MenuItem, Switch, FormControlLabel, Box } from '@material-ui/core';

import { Area, Survey } from 'types';

import { TextField } from 'components';

const useStyles = makeStyles(() =>
  createStyles({
    questionContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    selectControl: {
      minWidth: 120,
    },
    areaRelatedContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
);

interface Props {
  question: Survey.CreateQuestion;
  index: number;
  onValueChanged: (question: Survey.CreateQuestion, index: number) => void;
  areas: Area.Area[];
}

export function SurveyQuestionListItem(props: Props) {
  const classes = useStyles();
  const { areas, question, index, onValueChanged } = props;

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChanged(
      {
        ...question,
        text: event.target.value,
      },
      index
    );
  };

  const onEvaluateInverseChange = (checked: boolean) => {
    if (Survey.isQuestionAreaRelated(question)) {
      onValueChanged(
        {
          ...question,
          evaluateInverse: checked,
        },
        index
      );
    }
  };

  const onAreaChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onValueChanged(
      {
        ...question,
        areaId: event.target.value as number,
      },
      index
    );
  };

  const renderAreaRelatedQuestionControls = (question: Survey.CreateQuestion) => {
    if (Survey.isQuestionAreaRelated(question)) {
      return (
        <>
          <Box display="flex" className={classes.areaRelatedContainer} ml={2} mr={3}>
            <FormControl className={classes.selectControl}>
              <InputLabel id="area-input-label">Area</InputLabel>
              <Select id="area-select" value={question.areaId} onChange={onAreaChange}>
                {areas.map((area: Area.Area) => {
                  return (
                    <MenuItem key={area.name} value={area.id}>
                      {area.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={question.evaluateInverse}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onEvaluateInverseChange(event.target.checked)
                  }
                  value="evaluateInverse"
                  color="primary"
                />
              }
              label="Inverse"
              labelPlacement="start"
            />
          </Box>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Box className={classes.questionContainer} display="flex" key={index}>
      <TextField
        id="question-prompt"
        margin="normal"
        label="Question Text"
        value={question.text}
        onChange={onTextChange}
        type="text"
        fullWidth
        required
        variant="outlined"
      />
      {renderAreaRelatedQuestionControls(question)}
    </Box>
  );
}
